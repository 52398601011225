import React, { useState, useEffect, useCallback } from "react"
import { AiFillMinusCircle } from "react-icons/ai"
import { AiFillPlusCircle } from "react-icons/ai"
import { useAppDispatch } from "../../hooks"
import { updateCartItemThunk } from "../../features/cart/thunk"

type Props = {
  value: number
  onChange: (quantity: number) => void
}

function Counter({ value, onChange }: Props) {
  const [quantity, setQuantity] = useState(value)

  useEffect(() => {
    setQuantity(value)
  }, [value])

  let increment = () => {
    const newQuantity = value + 1
    onChange(newQuantity)
    setQuantity(newQuantity)
  }

  let decrement = () => {
    const newQuantity = value - 1
    if (newQuantity > 0) {
      onChange(newQuantity)
      setQuantity(newQuantity)
    }
  }

  return (
    <span className="row-center sm-gap no-select">
      <span className="span-btn col-center rg-font" onClick={decrement}>
        <AiFillMinusCircle />
      </span>
      <span>{value}</span>
      <span className="col-center span-btn rg-font" onClick={increment}>
        <AiFillPlusCircle />
      </span>
    </span>
  )
}

export default Counter
