import { CreateCategory } from "./tunk/createCategory";

export type Category = {
  id: number;
  name: string;
  subcategories: Category[];
  parentId?: number;
};

type Status = {
  isLoading: boolean;
  statusCode: number;
};
export const initialState = {
  categories: [] as Array<Category>,
  category: {
    name: "",
    parentId: "" as unknown
  } as Category | CreateCategory,
  status: {
    isLoading: true,
    statusCode: 404
  } as Status
};

export type CategoryState = typeof initialState;
export default initialState;
