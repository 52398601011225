import React, { ReactNode, createContext, useState, useEffect } from "react"
import LocalStorage from "../utils/LocalStorage"
import FetchWrapper from "../utils/FetchWrapper"
import { User } from "./AuthContext"

export type Profile = {
  fullName: string
  userId: string
  storeName: string
  terminalId: string
  storeId: string
  originId: string
  cartId: string
}

export type LoginType = {
  username: string
  password: string
  terminalId: string
}

type ProfileContextType = {
  profile: Profile | undefined
  login: (login: LoginType) => void
  logout: () => void
}

export const ProfileContext = createContext<ProfileContextType>({
  profile: undefined,
  login: () => {},
  logout: () => {}
})

type Props = {
  children: ReactNode
}

function ProfileProvider({ children }: Props) {
  const [profile, setProfile] = useState<Profile | undefined>(
    LocalStorage.get<Profile>("profile")
  )

  const login = async (LoginType: LoginType) => {
    const route = `/user/login`
    const res = await FetchWrapper.post(route, LoginType)
    const body = await res.json()

    if (res.status == 200) {
      console.log(body)
      LocalStorage.set("profile", body)
      setProfile(body)
    }
  }

  const logout = () => {
    LocalStorage.set("profile", undefined)
    setProfile(undefined)
  }

  const value = {
    profile,
    login,
    logout
  }

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  )
}

export default ProfileProvider
