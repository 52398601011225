import React, { useTransition } from "react"
import Template from "../../layout/Template"
import Alert from "../../components/common/Alert"
import PageHeader from "../../components/common/Header"
import { useTranslation } from "react-i18next"
import SupplyItemForm from "../../components/items/edit/SupplyItemForm"

function Supply() {
  const { t } = useTranslation()
  return (
    <Template>
      <PageHeader page_title={t("common.breadcrumb.supplyItem")} />
      <SupplyItemForm />
      <Alert />
    </Template>
  )
}

export default Supply
