import React, { useEffect, useRef } from "react"
import FormInput from "./FormInput"
import Button from "./Button"
import "./styles/BarcodeScanner.css"
import { CSSTransition } from "react-transition-group"
import {
  useAppDispatch,
  useAppSelector,
  useKeyboardShortcut
} from "../../hooks"
import {
  resetBarcodeScanner,
  setScannedBarcode,
  setScanning,
  setVisible
} from "../../features/appSlice"
import { useTranslation } from "react-i18next"

export enum BarcodeType {
  ITEM_BARCODE = "PIECE_BARCODE",
  WEIGHT_BARCODE = "WEIGHT_BARCODE",
  UNKNOWN = "UNKNOWN"
}

export type ScannedBarcode = {
  type: BarcodeType
  value: string
  length: number
  barcode: string
  weight?: number
}

function analyzeBarcode(value: string): ScannedBarcode {
  const length = value.length
  const type = value.startsWith("22")
    ? BarcodeType.WEIGHT_BARCODE
    : BarcodeType.ITEM_BARCODE

  const barcode =
    type == BarcodeType.ITEM_BARCODE ? value : value.substring(2, 8)

  const weight =
    type == BarcodeType.WEIGHT_BARCODE
      ? Number(value.substring(8, 12)) / 1000
      : 1

  return {
    length,
    value,
    type,
    barcode,
    weight
  }
}

function BarcodeScanner({
  onScan
}: {
  onScan: (scannedBarcode: ScannedBarcode) => void
}) {
  const { t } = useTranslation()
  const { isVisible, isScanning, scannedBarcode } = useAppSelector(
    state => state.app.barcodeScanner
  )
  const barcodeScannerRef = useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()

  const handleManualSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && scannedBarcode !== "") {
      const barcodeObject = analyzeBarcode(scannedBarcode)
      onScan(barcodeObject)
      dispatch(resetBarcodeScanner())
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setScannedBarcode(e.target.value))
  }

  //SHOW MANUAL SCANNER
  useKeyboardShortcut(({ event }) => {
    if (event.key == "Enter" && event.ctrlKey) {
      dispatch(setVisible(true))
    } else if (event.key == "Escape" && isVisible) {
      dispatch(resetBarcodeScanner())
    }
  })

  //HANDLE OUTSIDE MANUAL SCANNER CLICK
  useEffect(() => {
    function handleClick(event: MouseEvent) {
      if (barcodeScannerRef.current) {
        if (!barcodeScannerRef.current.contains(event.target as any)) {
          dispatch(resetBarcodeScanner())
        }
      }
    }

    window.addEventListener("click", handleClick)

    return () => {
      window.removeEventListener("click", handleClick)
    }
  }, [])

  useEffect(() => {
    function handleKeyDown(event: any) {
      const { key } = event
      const prefix = "Insert"
      const suffix = "Escape"

      //ACTIVATE BARCODE SCANNER
      if (key === prefix && !isScanning) {
        dispatch(setScanning(true))
        //@ts-ignore
        document.activeElement.blur()
      }

      //IF SCANNING IS ACTIVE
      if (isScanning) {
        dispatch(setScannedBarcode(scannedBarcode + key))
      }

      //DEACTIVATE BARCODE SCANNER
      if (key === suffix && isScanning) {
        onScan(analyzeBarcode(scannedBarcode))
        dispatch(resetBarcodeScanner())
      }
    }

    window.addEventListener("keydown", handleKeyDown)
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [scannedBarcode, onScan, isScanning])

  return (
    <CSSTransition
      in={isVisible}
      timeout={300}
      classNames="slideLeft"
      unmountOnExit
    >
      <div
        ref={barcodeScannerRef}
        className="container md-width row md-gap barcode-scanner sm-shadow"
      >
        <div className="flex">
          <FormInput
            label={t("itemDetails.barcode")}
            placeholder="اكتب الباركود هنا"
            type="number"
            value={scannedBarcode}
            onInput={handleChange}
            onKeyDown={handleManualSubmit}
            autoFocus
          />
        </div>
      </div>
    </CSSTransition>
  )
}
export default BarcodeScanner
