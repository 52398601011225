import i18n from "../config/i18n"

class FetchWrapper {
  public static apiUrl =
    process.env.NODE_ENV == "development"
      ? "http://localhost:8000/api/v1"
      : "https://api.omarsabra.com/api/v1"

  private static getLanguage = (): string => {
    const language = i18n.language
    return language
  }

  private static defaultHeaders = {
    "Accept-Language": this.getLanguage()
  }

  private static postInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...this.defaultHeaders
    }
  }

  private static getInit = {
    method: "GET",
    headers: {
      ...this.defaultHeaders
    }
  }

  private static putInit = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...this.defaultHeaders
    }
  }

  private static deleteInit = {
    method: "Delete",
    headers: {
      ...this.defaultHeaders
    }
  }

  public static async post(route: string, payload?: any): Promise<Response> {
    const URL = this.apiUrl + route
    const data = {
      ...this.postInit,
      body: JSON.stringify(payload)
    }

    let fetchRes: Promise<Response>

    try {
      fetchRes = fetch(URL, data)
    } finally {
    }
    return fetchRes
  }

  public static async get(route: string): Promise<Response> {
    const URL = this.apiUrl + route
    const data = {
      ...this.getInit
    }
    let fetchRes: Promise<Response>

    try {
      fetchRes = fetch(URL, data)
    } finally {
    }
    return fetchRes
  }

  public static async put(route: string, payload: any): Promise<Response> {
    const URL = this.apiUrl + route
    const data = {
      ...this.putInit,
      body: JSON.stringify(payload)
    }

    let fetchRes: Promise<Response>

    try {
      fetchRes = fetch(URL, data)
    } finally {
    }
    return fetchRes
  }

  public static async delete(route: string): Promise<Response> {
    const URL = this.apiUrl + route

    const data = {
      ...this.deleteInit
    }

    let fetchRes: Promise<Response>

    try {
      fetchRes = fetch(URL, data)
    } finally {
    }
    return fetchRes
  }
}

export default FetchWrapper
