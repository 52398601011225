import { configureStore } from "@reduxjs/toolkit"

import cartReducer from "./cart/cartSlice"
import itemReducer from "./item/itemSlice"
import appReducer from "./appSlice"
import orderReducer from "./order/orderSlice"
import identityReducer from "./identity/identitySlice"
import categoryReducer from "./category/categorySlice"

export const store = configureStore({
  reducer: {
    cart: cartReducer,
    item: itemReducer,
    app: appReducer,
    order: orderReducer,
    category: categoryReducer,
    identity: identityReducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat([])
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
