import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

function useBarcodeScanner() {
  const [searchParam, setSearchParam] = useSearchParams()
  const barcode = searchParam.get("barcode")
  const autoGenerateBarcode = searchParam.get("autoGenerateBarcode")

  const resetScannedBarcode = () => {
    setSearchParam(searchParam => {
      searchParam.delete("barcode")
      searchParam.delete("autoGenerateBarcode")
      return searchParam
    })
  }

  return {
    scannedBarcode: {
      barcode: barcode,
      autoGenerateBarcode: autoGenerateBarcode
    },
    resetScannedBarcode
  }
}

export default useBarcodeScanner
