import React from "react"

import { notFound } from "../assets/images"
import { ImageSize } from "../components/common/Img"

function NotFound() {
  return (
    <div style={{ margin: "auto" }} className="col-center">
      <img src={notFound} width={ImageSize.MEDUIM} alt="" />
      <h1>مفيش حاجة 🤷‍♂️</h1>
      <p>الرابط ده مش صح</p>
    </div>
  )
}

export default NotFound
