import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import initialState from "./initialState"
import extraReducers from "./extraReducers"
import { GetOrders } from "./thunk"

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setGetOrders: (state, action: PayloadAction<GetOrders>) => {
      state.getOrders.startDate = action.payload.startDate
      state.getOrders.endDate = action.payload.endDate
      state.getOrders.pageNumber = action.payload.pageNumber
    },
    resetReturnOrder: state => {
      state.returnOrder = initialState.returnOrder
    }
  },
  extraReducers
})

export const { setGetOrders, resetReturnOrder } = orderSlice.actions

export default orderSlice.reducer
