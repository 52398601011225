import React from "react"
import { Tab } from "../layout"
import { Route, Routes } from "react-router-dom"
import { ItemRoutes } from "./ItemRoutes"
import OrdersRoutes from "./OrdersRoutes"
import Home from "../templates/Home"
import CategoryRoutes from "./CategoryRoutes"
import NotFound from "./NotFound"
import IdentityRoutes from "./IdentityRoutes"

function AppRouter() {
  return (
    <Tab>
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="category/*" element={<CategoryRoutes />} />
        <Route path="item/*" element={<ItemRoutes />} />
        <Route path="origin/*" element={<OrdersRoutes />} />
        <Route path="order/*" element={<OrdersRoutes />} />
        <Route path="identity/*" element={<IdentityRoutes />} />
        {/* NOT FOUND */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Tab>
  )
}

export default AppRouter
