import React, { FormEvent } from "react"

import FormInput from "../../common/FormInput"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { setItem } from "../../../features/item/itemSlice"
import FormSelect from "../../common/FormSelect"

function ItemQuantity() {
  const { t } = useTranslation()
  const { item } = useAppSelector(state => state.item.createItem)
  const { identity } = useAppSelector(state => state.identity.getIdentity)

  const { itemFieldError } = useAppSelector(state => state.item.createItem)
  const dispatch = useAppDispatch()

  const onFormChange = (
    e:
      | FormEvent<HTMLInputElement>
      | React.SyntheticEvent<HTMLSelectElement, Event>
  ) =>
    dispatch(
      setItem({
        ...item,
        //@ts-ignore
        [e.target.name]: e.target.value
      })
    )

  return (
    <div className="container">
      <FormInput
        onInput={e => onFormChange(e)}
        label={t("itemDetails.quantity." + identity.unit)}
        value={item.quantity}
        type="number"
        error={itemFieldError.quantity as string}
        name="quantity"
        placeholder={
          t("itemForm.quantityPlaceholder." + identity.unit) as string
        }
      />
    </div>
  )
}

export default ItemQuantity
