import React, { useEffect } from "react"
import Template from "../../layout/Template"
import { useParams } from "react-router-dom"
import PageHeader from "../../components/common/Header"
import { useAppDispatch, useAppSelector } from "../../hooks"
import ItemNotFound from "../../components/items/error/ItemNotFound"
import Price from "../../components/common/Price"
import ItemGallery from "../../components/identity/read/IdentityGallery"
import Barcode from "../../components/common/Barcode"
import { getItemById } from "../../features/item/trunk"
import { resetGetItem } from "../../features/item/itemSlice"
import Dropdown from "../../components/common/Dropdown"
import FormSelect from "../../components/common/FormSelect"
import { Link } from "react-router-dom"

function Profile() {
  const { getItem } = useAppSelector(state => state.item)
  const dispatch = useAppDispatch()
  const { id } = useParams()

  useEffect(() => {
    if (id) dispatch(getItemById(id))

    return () => {
      dispatch(resetGetItem())
    }
  }, [])

  if (getItem.statusCode === 404) {
    return (
      <Template>
        <ItemNotFound />
      </Template>
    )
  }

  return (
    <Template>
      <PageHeader
        page_title="المنتجات"
        subTitle={getItem.item.identity.title}
      />
      <section style={{ margin: "auto" }} className="section row">
        <div>
          <ItemGallery imageUrls={getItem.item.identity.imageUrls} />
        </div>
        <div className="col rg-gap flex">
          <h1 className="lg-font">{getItem.item.identity.title}</h1>

          <div className="col sm-gap">
            <p>السعر</p>
            <Price className="lg-font" price={getItem.item.pricePerUnit} />
          </div>

          <div className="col sm-gap">
            <p>الكمية</p>
            <p className="rg-font">
              {getItem.item.identity.unit} {getItem.item.quantity}
            </p>
          </div>

          <div className="col sm-gap">
            <p>تكلفة الوحدة</p>
            <Price className="rg-font" price={getItem.item.costPerUnit} />
          </div>

          <div className="col sm-gap">
            <p>إجمالي التكلفة</p>
            <Price className="rg=font" price={getItem.item.costPerQuantity} />
          </div>
          <div className="row md-gap">
            <Link to={"update"}>تعديل</Link>
            <Link to={"supply"}>تزيد</Link>
            <Link to={"unpack"}>فتع</Link>
          </div>
        </div>
        <div className="end">
          <Barcode
            title={getItem.item.identity.title}
            barcode={getItem.item.identity.barcode}
          />
        </div>
      </section>
    </Template>
  )
}

export default Profile
