import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import initialState, { CreateIdentity } from "./initialState"
import extraReducers from "./extraReducers"

const identitySlice = createSlice({
  name: "identity",
  initialState,
  reducers: {
    setIdentity: (state, action: PayloadAction<CreateIdentity>) => {
      state.createIdentity.identity = action.payload
    },
    resetIdentity: state => {
      state.createIdentity = initialState.createIdentity
    },

    resetIdentity2: state => {
      state.createIdentity.identity = initialState.createIdentity.identity
    },
    resetErrorMessage: state => {
      state.createIdentity.errorMessage = ""
    },
    resetDeleteIdentity: state => {
      state.deleteIdentity = initialState.deleteIdentity
    },
    resetGetIdentity: state => {
      state.getIdentity = initialState.getIdentity
    }
  },
  extraReducers
})

export const {
  resetIdentity2,
  setIdentity,
  resetIdentity,
  resetGetIdentity,
  resetErrorMessage,
  resetDeleteIdentity
} = identitySlice.actions
export default identitySlice.reducer
