import React from "react"
import Price from "./Price"
import { useTranslation } from "react-i18next"

import "./styles/Analyse.css"
import { expenses, revenue, profit } from "../../assets/images"
import Utils from "../../utils/Utils"

type Props = {
  total_cost: number
  total_price: number
}

function Analyse({ total_cost, total_price }: Props) {
  const { t } = useTranslation()

  const persentage = ((total_price - total_cost) / total_cost) * 100
  const persentage_rounded = Utils.round(persentage)

  return (
    <div className="wrapper row rg-gap  center">
      <div className="wrapper main row rg-gap">
        <div className="container flex row rg-gap">
          <img src={expenses} className="img sm-icon" alt="" />
          <div className="wrapper col">
            <h3>{t("common.analyse.expenses")}</h3>
            <h2>{<Price price={total_cost} />}</h2>
          </div>
        </div>

        <div className="container flex row rg-gap">
          <img src={revenue} className="img sm-icon" alt="" />
          <div className="wrapper col">
            <h3>{t("common.analyse.revenue")}</h3>
            <h2>{<Price price={total_price} />}</h2>
          </div>
        </div>
      </div>

      <div className="container aside row rg-gap jc-sb">
        <div className="wrapper row md-gap">
          <img src={profit} className="img sm-icon" alt="" />
          <div className="wrapper col">
            <h3>{t("common.analyse.profit")}</h3>
            <h2>{<Price price={total_price - total_cost} />}</h2>
          </div>
        </div>
        <div className="wrapper row-center rg-font">
          <h1>{persentage_rounded}%</h1>
        </div>
      </div>
    </div>
  )
}

export default Analyse
