import React from "react"
import LayoutType from "."

type TemplateProps = LayoutType & {
  templateKey?: string
}

function Template({ children, templateKey }: TemplateProps) {
  return <React.Fragment key={templateKey}>{children}</React.Fragment>
}

export default Template
