import React, { HTMLAttributes, ReactNode, useRef } from "react"
import "./styles/Button.css"
import { Link } from "react-router-dom"
import { useKeyboardShortcut } from "../../hooks"

type Props = HTMLAttributes<HTMLButtonElement> & {
  variant: string
  size?: string
  role?: "link" | "icon"
  to?: string
  target?: string
  shortcut?: string
  disabled?: boolean
  shortcutCondition?: boolean
  children: ReactNode
  type?: "button" | "submit" | "reset"
  download?: string
}

function Button(props: Props) {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const linkRef = useRef<HTMLAnchorElement>(null)

  if (props.to && props.role === "link") {
    return (
      <Link
        role="btn"
        className={`${props.variant} ${props.size ? props.size : "sm"} btn`}
        to={props.to}
        style={props.style}
        download={props.download}
        target={props.target}
        ref={linkRef}
      >
        {props.children}
      </Link>
    )
  }

  return (
    <button
      onClick={props.onClick}
      className={`${props.variant} ${props.size ? props.size : "sm"} btn`}
      key={new Date().getTime()}
      disabled={props.disabled}
      type={props.type}
      style={props.style}
      ref={buttonRef}
    >
      {props.children}
    </button>
  )
}

export default Button
