import React, { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router-dom"
import { ImHome, ImMenu } from "react-icons/im"
import { BiCategory } from "react-icons/bi"
import { FaBarcode } from "react-icons/fa"

import { FaShoppingCart, FaBox } from "react-icons/fa"
import "./styles/Menu.css"
import TerminalLogout from "../auth/TerminalLogout"

type MenuItemProps = {
  title: string
  path: string
  children: ReactNode
}

function MenuItem(props: MenuItemProps) {
  const location = useLocation()

  const isActive = (path: string) => {
    const location_path = location.pathname
    const isCurrentLogation = location_path.startsWith(path)

    if (location_path === path) {
      return "menu-item menu-item__active"
    } else if (isCurrentLogation) {
      return "menu-item menu-item__active"
    } else {
      return "menu-item"
    }
  }

  return (
    <Link to={props.path} className={isActive(props.path)}>
      <span className="svg">{props.children}</span>
      <span>{props.title}</span>
    </Link>
  )
}

function TerminalMenu() {
  const { t } = useTranslation()
  return (
    <div className="menu">
      <div className="row-center rg-padding center">
        <h2>الأصدقاء</h2>
      </div>
      <div className="col flex jc-sb">
        <ul className="col">
          <MenuItem path="/terminal/item" title={t("menu.items")}>
            <FaBox />
          </MenuItem>
          <MenuItem path="/terminal/cart" title={t("menu.cart")}>
            <FaShoppingCart />
          </MenuItem>
        </ul>

        <div className="col sm-gap sm-padding">
          <TerminalLogout />
          <p className="center flex" style={{ color: "gray", fontSize: 10 }}>
            Powered by Omar Sabra
          </p>
        </div>
      </div>
    </div>
  )
}

function AppMenu() {
  const { t } = useTranslation()
  return (
    <div className="menu">
      <div className="menu__logo rg-padding">
        <div className="wrapper row row-center center">
          <h2>الأصدقاء</h2>
        </div>
      </div>
      <div className="flex conatiner rm-pd rm-bg col jc-sb">
        <ul className="col">
          <MenuItem path="/app/home" title={t("menu.home")}>
            <ImHome />
          </MenuItem>
          <MenuItem path="/app/identity" title={"الهواية"}>
            <FaBarcode />
          </MenuItem>

          <MenuItem path="/app/item" title={t("menu.items")}>
            <FaBox />
          </MenuItem>

          <MenuItem path="/app/order" title={t("menu.orders")}>
            <ImMenu />
          </MenuItem>

          <MenuItem path="/app/category" title={t("menu.itemCategory")}>
            <BiCategory />
          </MenuItem>
        </ul>
      </div>
      <div className="row center md-padding">
        <p style={{ color: "gray", fontSize: 10 }}>Powered by Omar Sabra</p>
      </div>
    </div>
  )
}

function Menu() {
  const { t } = useTranslation()
  const pathName = window.location.pathname
  const rootPath = pathName.split("/")[1]

  if (rootPath == "terminal") return <TerminalMenu />

  return <AppMenu />
}

export default Menu
