import { createAsyncThunk } from "@reduxjs/toolkit"

import FetchWrapper from "../../utils/FetchWrapper"
import LocalStorage from "../../utils/LocalStorage"
import { Profile, ProfileContext } from "../../context/ProfileContext"
import { useContext } from "react"
import { CreateCartItem } from "./initialState"

export const checkoutCartThunk = createAsyncThunk(
  "cart/checkoutCart",
  async () => {
    const profile = LocalStorage.get("profile") as Profile

    const route = `/cart/${profile.cartId}/checkout`
    const res = await FetchWrapper.post(route)

    const body = await res.json()

    return {
      body,
      statusCode: res.status
    }
  }
)

export const getCartThunk = createAsyncThunk("cart/getCart", async () => {
  const profile = LocalStorage.get<Profile>("profile") as Profile

  const route = `/terminal/${profile.terminalId}/cart?userId=${profile.userId}`
  const res = await FetchWrapper.get(route)
  const body = await res.json()

  return {
    statusCode: res.status,
    body
  }
})

export const createCartItemThunk = createAsyncThunk(
  "cart/createCartItem",
  async (cartItem: CreateCartItem) => {
    const profile = LocalStorage.get<Profile>("profile") as Profile

    const route = `/cart/${profile.cartId}/item`

    const res = await FetchWrapper.post(route, cartItem)
    const body = await res.json()

    return {
      statusCode: res.status,
      body
    }
  }
)

export type UpdateCartItem = {
  id: number
  quantity: number
  quantityPerUnit?: number
}

export const updateCartItemThunk = createAsyncThunk(
  "cart/updateCartItem",
  async (cartItem: UpdateCartItem) => {
    const profile = LocalStorage.get("profile") as Profile

    const route = `/cart/${profile.cartId}/item/${cartItem.id}`
    const res = await FetchWrapper.put(route, cartItem)

    const statusCode = res.status
    const body = await res.json()

    return {
      statusCode,
      body
    }
  }
)

export const deleteCartItemThunk = createAsyncThunk(
  "cart/deleteCartItem",
  async (id: string) => {
    const profile = LocalStorage.get("profile") as Profile
    const route = `/cart/${profile.cartId}/item/${id}`
    const res = await FetchWrapper.delete(route)

    const statusCode = res.status
    const cart = await res.json()

    return {
      statusCode: statusCode,
      body: cart
    }
  }
)

export const clearCartThunk = createAsyncThunk("cart/clearCart", async () => {
  const profile = LocalStorage.get("profile") as Profile
  const route = `/cart/${profile.cartId}/clear`
  const res = await FetchWrapper.delete(route)

  const statusCode = res.status
  const cart = await res.json()

  return {
    statusCode: statusCode,
    body: cart
  }
})
