import { DefaultTFuncReturn } from "i18next";
import React, { ReactNode } from "react";

type Props = {
  title?: any;
  children?: ReactNode;
  description?: string | number | DefaultTFuncReturn;
};

function Title({ title, description, children }: Props) {
  return (
    <div className="title">
      <h2 className="title__title">{title ? title : children}</h2>
      {description ? (
        <p className="title__description">{description}</p>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Title;
