import { useState, useRef, useEffect, useCallback } from "react";

type CallbackArgs = {
  event: KeyboardEvent;
};

function useKeyboardShortcut(callback: ({}: CallbackArgs) => void) {
  useEffect(() => {
    function handleShortcut(event: KeyboardEvent) {
      if (!event.repeat) {
        callback({ event });
      }
    }

    window.addEventListener("keydown", handleShortcut);

    return () => {
      window.removeEventListener("keydown", handleShortcut);
    };
  }, [callback]);

  return null;
}

export default useKeyboardShortcut;
