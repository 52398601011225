import React, { ReactNode, createContext, useState, useEffect } from "react"

export type User = {
  name: string
  password: string
  origin: string
}
type AuthContextType = {
  user: User | null
  login: (user: User) => void
  logout: () => void
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  login: () => {},
  logout: () => {}
})

type Props = {
  children: ReactNode
}

function AuthProvider({ children }: Props) {
  const [user, setUser] = useState<User | null>(null)

  const login = (newUser: User) => {
    setUser(newUser)
  }

  const logout = () => {
    setUser(null)
  }

  const value = {
    user,
    login,
    logout
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider
