import React from "react"
import ReactDOM from "react-dom/client"

import "./layout/layout.css"

import "./index.css"
import Router from "./routes/Router"
import i18n from "./config/i18n"
import ProfileProvider from "./context/ProfileContext"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
const html = document.querySelector("html")

if (html) {
  html.lang = i18n.language
  html.dir = i18n.dir()

  const pathName = location.pathname

  if (pathName.startsWith("/terminal")) html.setAttribute("data-theme", "light")
}

root.render(
  <ProfileProvider>
    <Router />
  </ProfileProvider>
)
