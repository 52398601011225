import React, { useEffect, useRef, useState } from "react"
import "./styles/Img.css"
import { notFound } from "../../assets/images"

type Props = React.ImgHTMLAttributes<HTMLImageElement> & {
  imageSize: ImageSize
  heightFit?: boolean
}

type Dimentions = {
  width?: string
  height?: string
}

export enum ImageSize {
  THUMBNAIL = 65,
  REGULAR = 500,
  LARGE = 600,
  MEDUIM = 300,
  FIT = "100%"
}

function Img(props: Props) {
  const imgRef = useRef<HTMLImageElement>(null)

  const [dimentions, setDimentions] = useState({
    width: "100%"
  } as Dimentions)

  useEffect(() => {
    const handleImgLoad = () => {
      if (imgRef.current) {
        const width = imgRef.current.naturalWidth
        const height = imgRef.current.naturalHeight

        if (height > width) {
          setDimentions({
            height: "100%"
          })
        }
      }
    }

    imgRef.current && imgRef.current.addEventListener("load", handleImgLoad)

    return () => {
      imgRef.current &&
        imgRef.current.removeEventListener("load", handleImgLoad)
    }
  }, [imgRef])

  const style = props.heightFit
    ? { height: props.imageSize }
    : { width: props.imageSize }

  return (
    <div style={style} className="img relative">
      <img
        onClick={props.onClick}
        {...dimentions}
        src={props.src || notFound}
        alt=""
        className="animation--fade-in"
        loading={"lazy"}
        ref={imgRef}
        onError={props.onError}
      />
    </div>
  )
}

export default Img
