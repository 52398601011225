import React from "react"
import { useTranslation } from "react-i18next"

type Props = {
  price: number
  currency?: string
  className?: string
}
function Price(props: Props) {
  const { t } = useTranslation()
  const currency = localStorage.getItem("currency")
    ? localStorage.getItem("currency")
    : t("currency.EGP")

  const color = props.price <= 0 ? "color-danger" : ""
  const price = Number(props.price) ? props.price : 0
  const rounded = Math.round(price * 100) / 100
  return (
    <span className={`price ${color} ${props.className && props.className}`}>
      {rounded} {props.currency ? props.currency : ` ${currency}`}
    </span>
  )
}

export default Price
