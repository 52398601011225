import { createSlice } from "@reduxjs/toolkit";

import initialState from "./initialState";
import extraReducers from "./extraReducers";

const categorySlice = createSlice({
  name: "category",
  reducers: {
    restCategories: state => {
      state.categories = initialState.categories;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    resetCategory: state => {
      state.category = initialState.category;
      state.status = initialState.status;
    }
  },
  initialState,
  extraReducers
});

export default categorySlice.reducer;
export const {
  restCategories,
  setCategory,
  resetCategory
} = categorySlice.actions;
