import { createAsyncThunk } from "@reduxjs/toolkit";
import FetchWrapper from "../../../utils/FetchWrapper";
import { Category } from "../initialState";
import { HttpStatusCode } from "axios";
import { showAlert } from "../../appSlice";
import { AlertVariant } from "../../../types/AlertType";
import getCategories from "./getCategories";

const deleteCategory = createAsyncThunk(
  "category/deleteCategory",
  async (id: number, { dispatch }) => {
    const categoryRes = await FetchWrapper.delete(`/category/${id}`);

    if (categoryRes.status == HttpStatusCode.NoContent) {
      dispatch(
        showAlert({
          variant: AlertVariant.success,
          messageKey: "common.alert.message.deleteCategorySuccess"
        })
      );
      dispatch(getCategories(false));
    }
  }
);

export default deleteCategory;
