import React from "react"
import { Routes, Route } from "react-router-dom"

import Categories from "../templates/category/Categories"

import Category from "../templates/category/Category"
import AddCategory from "../templates/category/AddCategory"
import EditCategory from "../templates/category/EditCategory"

function CategoryRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Categories />} />
      <Route path=":id" element={<Category />} />
      <Route path="add" element={<AddCategory />} />
      <Route path=":id/edit" element={<EditCategory />} />
    </Routes>
  )
}

export default CategoryRoutes
// Add similar sections for Orders, Cart, Test, etc.
