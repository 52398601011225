import React from "react"
import Img, { ImageSize } from "./Img"
import { codingGif } from "../../assets/images"

function UnderDevelopment() {
  return (
    <div style={{ margin: "auto" }} className="section col-center">
      <Img src={codingGif} imageSize={ImageSize.MEDUIM} />
      <div className="col-center sm-gap">
        <h1>تحت الانشاء 🙃</h1>
        <p>تعالى بعدين</p>
      </div>
    </div>
  )
}

export default UnderDevelopment
