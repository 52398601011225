import React, { useEffect, useState } from "react"
import Template from "../../layout/Template"
import Header from "../../components/common/Header"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { useParams } from "react-router-dom"
import Barcode from "../../components/common/Barcode"
import IdentityGallery from "../../components/identity/read/IdentityGallery"
import { getIdentityById } from "../../features/identity/trunk"
import { resetGetIdentity } from "../../features/identity/identitySlice"
import Model from "../../components/common/Model"
import Button from "../../components/common/Button"
import { MdDelete } from "react-icons/md"
import { showModel } from "../../features/appSlice"
import DeleteIdentityModel from "../../components/model/DeleteIdentityModel"
import ItemNotFound from "../../components/items/error/ItemNotFound"
import Loading from "../../components/common/Loading"

function Identity() {
  const { getIdentity } = useAppSelector(state => state.identity)
  const dispatch = useAppDispatch()
  const { id } = useParams() as { id: string }

  useEffect(() => {
    if (getIdentity.statusCode == 0) {
      dispatch(getIdentityById(id))
    }

    if (getIdentity.statusCode == 200) {
      return () => {
        dispatch(resetGetIdentity())
      }
    }
  }, [getIdentity.statusCode])

  if (getIdentity.statusCode == 404) {
    return <ItemNotFound />
  }

  if (getIdentity.statusCode == 200) {
    return (
      <Template>
        <Header page_title="الهواية" subTitle={getIdentity.identity.title} />
        <DeleteIdentityModel />
        <section style={{ margin: "auto" }} className="section row">
          <div>
            <IdentityGallery imageUrls={getIdentity.identity.imageUrls} />
          </div>
          <div className="col rg-gap flex">
            <h1 className="lg-font">{getIdentity.identity.title}</h1>
            <p>{getIdentity.identity.description}</p>
            <span>{getIdentity.identity.unit}</span>
            <Button
              onClick={() => dispatch(showModel())}
              variant="icon outline danger"
            >
              <MdDelete />
            </Button>
          </div>
          <div className="end">
            <Barcode
              title={getIdentity.identity.title}
              barcode={getIdentity.identity.barcode}
            />
          </div>
        </section>
      </Template>
    )
  }
  return <></>
}

export default Identity
