import React from "react";

type Props = {
  date: string;
};
function DateFormatter({ date }: Props) {
  let final_date = "";

  const order_time = new Date(date).toLocaleTimeString();
  const order_date = new Date(date);

  const today_date = new Date();

  if (order_date.toDateString() == today_date.toDateString()) {
    final_date = "Today at ";
  } else if (order_date.getDate() == today_date.getDate() - 1) {
    final_date = "yasterday at ";
  } else {
    final_date = order_date.toDateString();
  }

  return <span>{`${final_date} ${order_time}`}</span>;
}

export default DateFormatter;
