import React from "react"
import { AuthContext, User } from "../../context/AuthContext"
import FormInput from "../common/FormInput"
import Button from "../common/Button"
import Img, { ImageSize } from "../common/Img"
import { logo_png, pp } from "../../assets/images"

function AppLogin() {
  const { login, user } = React.useContext(AuthContext)
  const [localUser, setUser] = React.useState<User>({} as User)

  console.log("login rendered")

  const handle = (e: any) => {
    setUser(currentUser => {
      return {
        ...currentUser,
        [e.target.name]: e.target.value,
        origin: "1"
      }
    })
  }
  return (
    <div style={{ margin: "auto" }} className="section col-center">
      {user && <p>{user.name}</p>}
      <div
        style={{ width: 300 }}
        className="container col-center rg-padding rg-gap"
      >
        <Img imageSize={ImageSize.THUMBNAIL} src={logo_png} />
        <div className="flex col-center md-gap">
          <FormInput
            name="name"
            placeholder="اسم المستخدم"
            onInput={e => handle(e)}
          />
          <FormInput
            onInput={e => handle(e)}
            name="password"
            type="passwrod"
            placeholder="كلمت المرور"
          />
        </div>
        <Button onClick={() => login(localUser)} variant="">
          تسجيل دخول
        </Button>
      </div>
    </div>
  )
}

export default AppLogin
