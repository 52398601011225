import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import initialState, {
  CreateCartItem,
  CreateCartItemState,
  UpdateCartItem
} from "./initialState"
import { extraReducers } from "./extraReducers"

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    resetCheckoutCart: state => {
      state.checkoutCart.statusCode = 0
    },
    setCartItem: (state, action: PayloadAction<CreateCartItem>) => {
      state.createCartItem.cartItem = action.payload
    },
    resetCreateCartItem: state => {
      state.createCartItem = initialState.createCartItem
    },
    updateCartItem: (state, action: PayloadAction<UpdateCartItem>) => {
      state.updateCartItem.cartItem = action.payload
    },
    resetUpdateCartItem: state => {
      state.updateCartItem = initialState.updateCartItem
    }
  },
  extraReducers: extraReducers
})

export const {
  resetCheckoutCart,
  resetCreateCartItem,
  resetUpdateCartItem,
  updateCartItem,
  setCartItem
} = cartSlice.actions
export default cartSlice.reducer
