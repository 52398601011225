import React from "react"
import { Route, Routes } from "react-router-dom"
import Orders from "../templates/order/Orders"
import Order from "../templates/order/Order"

function OrdersRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Orders />} />
      <Route path=":id" element={<Order />} />
    </Routes>
  )
}

export default OrdersRoutes
