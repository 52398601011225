import React from "react"
import Template from "../../layout/Template"
import PageHeader from "../../components/common/Header"
import FormInput from "../../components/common/FormInput"
import FormSelect from "../../components/common/FormSelect"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { createCategory, getCategories } from "../../features/category/tunk"
import {
  resetCategory,
  restCategories,
  setCategory
} from "../../features/category/categorySlice"
import Button from "../../components/common/Button"
import getCategory from "../../features/category/tunk/getCategory"
import { useParams } from "react-router-dom"
import updateCategory from "../../features/category/tunk/updateCategory"

function EditCategory() {
  const { categories, category } = useAppSelector(state => state.category)
  const params = useParams()
  const id = (params.id as unknown) as number
  const dispatch = useAppDispatch()

  const handelInput = (
    e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
  ) => {
    //@ts-ignore

    dispatch(
      setCategory({
        ...category,
        //@ts-ignore
        [e.target.name]: e.target.value
      })
    )
  }

  React.useEffect(() => {
    dispatch(getCategory(id))
    dispatch(getCategories(false))

    return () => {
      dispatch(resetCategory())
    }
  }, [])
  return (
    <Template>
      <PageHeader page_title="تعديل" />
      <section className="section center row">
        <div className="container col aside rg-gap">
          <FormInput
            label={"الاسم"}
            value={category.name}
            name="name"
            onInput={handelInput}
          />
          <FormSelect
            onChange={handelInput}
            value={category.parentId || ""}
            name="parentId"
            label={"التصنيف الفرعي"}
          >
            <option key={0} value={""}>
              مش موجود
            </option>
            {categories.map(cat => (
              <option key={cat.id} value={cat.id}>
                {cat.name}
              </option>
            ))}
          </FormSelect>
          <Button
            onClick={() => dispatch(updateCategory({ id, category }))}
            variant="end"
          >
            احفظ
          </Button>
        </div>
      </section>
    </Template>
  )
}

export default EditCategory
