import { createAsyncThunk } from "@reduxjs/toolkit";
import FetchWrapper from "../../../utils/FetchWrapper";
import { Category } from "../initialState";

const getCategories = createAsyncThunk(
  "category/getCategories",
  async (recursive: boolean): Promise<Category[]> => {
    const categoriesRes = await FetchWrapper.get(
      `/category?recursive=${recursive}`
    );

    const categories = categoriesRes.json();
    return categories;
  }
);

export default getCategories;
