import React, { useState } from "react"
import Img, { ImageSize } from "../../common/Img"
import Button from "../../common/Button"

import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle
} from "react-icons/io"
import { logo_png, shoppingGif } from "../../../assets/images"

type Props = {
  imageUrls?: Array<string>
  imageSize?: ImageSize
}

function IdentityGallery({ imageUrls, imageSize }: Props) {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [srcSet, setSrcSet] = useState<Array<string>>([])

  React.useEffect(() => {
    if (imageUrls && imageUrls.length > 0) {
      setSrcSet(imageUrls)
    }

    return () => {
      setSrcSet([])
    }
  }, [imageUrls])

  const navigate = (index: number) => {
    setCurrentIndex(index)
  }

  const next = () => {
    setCurrentIndex(index => {
      if (index < srcSet.length - 1) return index + 1
      return 0
    })
  }

  const prev = () => {
    setCurrentIndex(index => {
      if (index > 0) return index - 1
      return srcSet.length - 1
    })
  }
  return (
    <div className="no-select col md-gap">
      <div className="relative">
        <Img
          imageSize={imageSize ? imageSize : ImageSize.REGULAR}
          src={srcSet[currentIndex]}
        />

        <span
          onClick={() => prev()}
          className="pointer button xlg-font float--middle-right"
        >
          <IoIosArrowDroprightCircle />
        </span>

        <span
          onClick={() => next()}
          className="pointer button xlg-font float--middle-left"
        >
          <IoIosArrowDropleftCircle />
        </span>
      </div>
      {srcSet.length > 1 && (
        <div className="sm-gap  row md-gap">
          {srcSet.map((src, index) => (
            <Img
              onClick={() => navigate(index)}
              key={index}
              imageSize={ImageSize.THUMBNAIL}
              src={src}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default IdentityGallery
