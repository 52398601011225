import { shoppingGif } from "../../assets/images"
import TableType from "../../types/TableType"

export enum Unit {
  PKG = "PKG",
  PC = "PC",
  KG = "KG",
  CTR = "CTR"
}

export type Category = {
  id: string
  name: string
  subcategories: Array<Category>
}

export const identity = {
  id: "",
  title: "",
  categories: [] as Array<Category>,
  barcode: "",
  description: "",
  autoGenerateBarcode: false,
  imageUrls: [],
  parentBarcode: "",
  storeId: 1,
  unit: Unit.PC,
  quantityPerUnit: 1
}
export type IdentityType = typeof identity

const fieldError = {
  title: "",
  barcode: "",
  description: "",
  unit: "",
  quantityPerUnit: ""
}

const createIdentity = {
  title: "",
  barcode: "",
  description: "",
  autoGenerateBarcode: false,
  autoDownloadImages: false,
  parentBarcode: "",
  storeId: 1,
  unit: Unit.PC,
  quantityPerUnit: 1,
  appFileName: ""
}

export type CreateIdentity = typeof createIdentity

const updateIdentity = {
  title: "",
  barcode: "",
  autoGenerateBarcode: false,
  parentBarcode: "",
  description: "",
  quantityPerUnit: 1
}
export type UpdateIdentity = typeof updateIdentity

const initialState = {
  getIdentity: {
    identity,
    statusCode: 0,
    isLoading: true,
    errorMessage: ""
  },
  createIdentity: {
    errorMessage: "",
    identity: createIdentity,
    statusCode: 0,
    fieldError: fieldError
  },
  updateIdentity: {
    identity: updateIdentity,
    statusCode: 0
  },
  deleteIdentity: {
    statusCode: 0
  },

  getIdentities: {
    searchQuery: "",
    pageNumber: 1,
    statusCode: 0,
    barcode: "",
    identityTable: {
      remaining_elements: 0,
      elements_per_page: 1,
      total_elements: 0,
      current_page: 1,
      elements: [] as Array<IdentityType>
    } as TableType<IdentityType>
  }
}

export type IdentityState = typeof initialState
export default initialState
