import React, { useEffect } from "react"
import Template from "../../layout/Template"
import { ItemCost, ItemQuantity, ItemStatus } from "../../components/items/edit"
import { useAppDispatch, useAppSelector } from "../../hooks"
import Analyse from "../../components/common/Analyse"
import ItemPricePerUnit from "../../components/items/edit/ItemPricePerUnit"
import Header from "../../components/common/Header"
import { useTranslation } from "react-i18next"

import {
  resetGetItem,
  resetItem,
  resetUpdateItem,
  setItem
} from "../../features/item/itemSlice"
import IdentityGallery from "../../components/identity/read/IdentityGallery"
import { ImageSize } from "../../components/common/Img"

import Barcode from "../../components/common/Barcode"
import { useParams } from "react-router-dom"

import { getItemById, updateItemTrunk } from "../../features/item/trunk"
import { showAlert } from "../../features/appSlice"
import { AlertVariant } from "../../types/AlertType"

function UpdateItem() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { getItem, createItem, updateItem } = useAppSelector(
    state => state.item
  )

  const { id } = useParams() as { id: string }

  //CLEAN UP
  useEffect(() => {
    return () => {
      dispatch(resetGetItem())
      dispatch(resetUpdateItem())
      dispatch(resetItem())
    }
  }, [])

  useEffect(() => {
    if (getItem.statusCode == 0 && updateItem.statusCode == 0) {
      dispatch(getItemById(id))
    }
    if (getItem.statusCode == 200) {
      dispatch(
        setItem({ ...getItem.item, barcode: getItem.item.identity.barcode })
      )
    }
  }, [getItem.statusCode])

  useEffect(() => {
    if (updateItem.statusCode == 200) {
      dispatch(
        showAlert({ variant: AlertVariant.success, messageKey: "خلصانه" })
      )
      dispatch(resetUpdateItem())
    }
  }, [updateItem.statusCode])

  return (
    <Template>
      <Header
        headerCta={
          <Barcode
            title={getItem.item.identity.title}
            barcode={getItem.item.identity.barcode}
          />
        }
        subTitle={getItem.item.identity.title}
        page_title={t("common.breadcrumb.add")}
      />

      <div className="section col rg-gap center">
        <Analyse
          total_cost={createItem.item.costPerQuantity}
          total_price={createItem.item.pricePerUnit * createItem.item.quantity}
        />
        <div className="wrapper center row rg-gap ">
          <div className="wrapper main rg-gap col">
            <ItemPricePerUnit />
            <ItemQuantity />
            <ItemCost />
          </div>

          <div className="wrapper aside rg-gap col">
            <ItemStatus
              onSave={() =>
                dispatch(updateItemTrunk({ item: createItem.item, id: id }))
              }
            />

            <IdentityGallery
              imageUrls={getItem.item.identity.imageUrls}
              imageSize={ImageSize.FIT}
            />
          </div>
        </div>
      </div>
    </Template>
  )
}

export default UpdateItem
