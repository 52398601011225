import React, { useContext } from "react"
import { ProfileContext } from "../../context/ProfileContext"
import { BiLogOut } from "react-icons/bi"

function TerminalLogout() {
  const { logout } = useContext(ProfileContext)

  return (
    <span className="pointer row-center sm-gap" onClick={() => logout()}>
      <BiLogOut />
      <p>تسجيل خروج</p>
    </span>
  )
}

export default TerminalLogout
