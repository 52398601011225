import { createAsyncThunk } from "@reduxjs/toolkit"
import FetchWrapper from "../../utils/FetchWrapper"

import { ServerErrorMessage } from "../../types/ServerResponse"
import {
  CreateItemType,
  ItemStatusType,
  ItemType,
  TerminalItemType,
  UpdateItemType
} from "./initialState"
import TableType from "../../types/TableType"
import Utils from "../../utils/Utils"
import LocalStorage from "../../utils/LocalStorage"
import { Profile } from "../../context/ProfileContext"

//GET ITEM

export const getItemById = createAsyncThunk(
  "item/getItemById",
  async (id?: string) => {
    const itemReq = await FetchWrapper.get(`/item/${id}`)
    const body = await itemReq.json()
    const statusCode = itemReq.status

    return {
      body,
      statusCode
    }
  }
)

type GetOriginItemRequest = {
  barcode: string
  originId: string
}

export const getOriginItem = createAsyncThunk(
  "item/getOriginItem",
  async ({ barcode, originId }: GetOriginItemRequest) => {
    const route = `/origin/${originId}/item/${barcode}`
    const itemReq = await FetchWrapper.get(route)

    const body = await itemReq.json()
    const statusCode = itemReq.status
    return {
      body,
      statusCode
    }
  }
)

export const getTerminalItem = createAsyncThunk(
  "item/getTerminalItem",
  async (barcode: string) => {
    const profile = LocalStorage.get("profile") as Profile
    const route = `/terminal/${profile.terminalId}/item/${barcode}`
    const itemReq = await FetchWrapper.get(route)

    const body = await itemReq.json()
    const statusCode = itemReq.status
    return {
      body,
      statusCode
    }
  }
)

//GET ITEMS

type GetItemsRequest = {
  itemStatus: ItemStatusType
  pageNumber: number
  searchQuery: string
  barcode: string
}

export const getItems = createAsyncThunk(
  "item/getItems",
  async ({ itemStatus, pageNumber, searchQuery, barcode }: GetItemsRequest) => {
    const pageFilter = `&page=${pageNumber}`
    const titleFilter = searchQuery ? `&title=${searchQuery}` : ""
    const itemStatusFilter = itemStatus ? `&itemStatus=${itemStatus}` : ""
    const barcodeFilter = barcode ? `&barcode=${barcode}` : ""

    const route = `/item?store=1${titleFilter}${pageFilter}${barcodeFilter}${itemStatusFilter}`

    const itemsRes = await FetchWrapper.get(route)
    const responseHeaders = itemsRes.headers
    const items: ItemType[] = await itemsRes.json()

    const itemsTable: TableType<ItemType> = {
      total_elements: Number(responseHeaders.get("X-Total-Count")),
      total_pages: Number(responseHeaders.get("X-Total-Pages")),
      elements_per_page: Number(responseHeaders.get("X-Elements-Per-Page")),
      elements: items,
      remaining_elements: Number(responseHeaders.get("X-Elements-Items")),
      current_page: Number(responseHeaders.get("X-Current-Page"))
    }

    return {
      itemsTable,
      searchQuery,
      itemStatus,
      pageNumber,
      barcode
    }
  }
)

export const getTerminalItems = createAsyncThunk(
  "item/getTerminalItems",
  async ({
    pageNumber,
    searchQuery
  }: {
    pageNumber: number
    searchQuery: string
  }) => {
    const profile = LocalStorage.get("profile") as Profile
    const titleFilter = searchQuery ? `&title=${searchQuery}` : ""
    const page = `page=${pageNumber}`

    const route = `/terminal/${profile.terminalId}/item?${page}${titleFilter}`

    const itemsRes = await FetchWrapper.get(route)

    const body = await itemsRes.json()

    const itemsTable = Utils.getTableFromHeaders<TerminalItemType>(
      itemsRes.headers,
      body
    )

    console.log(body)

    return {
      itemsTable,
      searchQuery,
      pageNumber
    }
  }
)

//CREATE ITEM
export const createItem = createAsyncThunk(
  "item/createItem",
  async (item: CreateItemType, { dispatch }) => {
    const itemReq = await FetchWrapper.post("/item", item)
    const body = await itemReq.json()

    const statusCode = itemReq.status
    return {
      body,
      statusCode
    }
  }
)

type UpdateItemRequest = {
  item: UpdateItemType
  id: string
}

export const updateItemTrunk = createAsyncThunk(
  "item/updateItem",
  async ({ item, id }: UpdateItemRequest, { dispatch }) => {
    const updateItemRes = await FetchWrapper.put(`/item/${id}`, item)
    const body = await updateItemRes.json()
    const statusCode = updateItemRes.status

    return {
      body,
      statusCode
    }
  }
)

type SupplyItem = {
  id: string
  quantity: number
  costPerQuantity: number
}

export const supplyItem = createAsyncThunk(
  "item/supplyItem",
  async (supplyItem: SupplyItem, { dispatch }) => {
    const supplyItemRes = await FetchWrapper.put(
      `/item/${supplyItem.id}/supply`,
      supplyItem
    )
    const body = await supplyItemRes.json()

    const statusCode = supplyItemRes.status

    return {
      body,
      statusCode
    }
  }
)

type UnpackItemRequest = {
  id: string
  quantity: number
}

export const unpacKItem = createAsyncThunk(
  "item/unpacKItem",
  async ({ id, quantity }: UnpackItemRequest) => {
    const res = await FetchWrapper.put(`/item/${id}/unpack`, {
      quantity: quantity
    })

    const body = await res.json()
    const statusCode = res.status

    return {
      body,
      statusCode
    }
  }
)
