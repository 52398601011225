import React from "react";
import { Outlet } from "react-router-dom";
import LayoutType from ".";

function Page({ children }: LayoutType) {
  return (
    <div className="page__layout">
      <main className="main-content">{children ? children : <Outlet />}</main>
    </div>
  );
}

export default Page;
