import React from "react"
import { notFound } from "../../../assets/images"
import { ImageSize } from "../../common/Img"

function ItemNotFound() {
  return (
    <div style={{ margin: "auto" }} className="col-center">
      <img width={ImageSize.MEDUIM} src={notFound} alt="" />
      <h1>مفيش حاجة 🤷‍♂️</h1>
      <p>السلعة دي مش موجودة او ممكن تكون اتمسحت</p>
    </div>
  )
}

export default ItemNotFound
