import React, { useEffect } from "react"
import Model from "../common/Model"
import Button from "../common/Button"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { hideModel, showAlert } from "../../features/appSlice"
import { deleteIdentity } from "../../features/identity/trunk"
import { useParams } from "react-router-dom"
import { AlertVariant } from "../../types/AlertType"
import {
  resetDeleteIdentity,
  resetGetIdentity
} from "../../features/identity/identitySlice"
import { resetGetItem } from "../../features/item/itemSlice"

function DeleteIdentityModel() {
  const { id } = useParams() as { id: string }
  const { statusCode } = useAppSelector(state => state.identity.deleteIdentity)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (statusCode == 204) {
      dispatch(
        showAlert({ variant: AlertVariant.success, messageKey: "خلويص" })
      )
      dispatch(resetGetIdentity())

      return () => {
        dispatch(resetDeleteIdentity())
      }
    }
  }, [statusCode])
  return (
    <Model>
      <div style={{ zIndex: 2000 }} className="container col-center md-gap ">
        <div className="col-center sm-gap">
          <h1>انت متأكد؟😱</h1>
          <p>لو مسحت الهوية كل المنتجات اللي مربوطة بيها هتتمسح</p>
        </div>
        <div className="row-center flex jc-sb md-gap">
          <Button onClick={() => dispatch(hideModel())} variant="">
            لا
          </Button>
          <Button
            onClick={() => {
              dispatch(deleteIdentity(id))
            }}
            variant="outline danger"
          >
            ايوة
          </Button>
        </div>
      </div>
    </Model>
  )
}

export default DeleteIdentityModel
