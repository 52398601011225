import React, { useContext, useState } from "react"
import FormInput from "../common/FormInput"
import Button from "../common/Button"
import { LoginType, ProfileContext } from "../../context/ProfileContext"

function TerminalLogin() {
  const { login } = useContext(ProfileContext)
  const [loginBody, setLoginBody] = useState<LoginType>({} as LoginType)

  const handleInput = (e: any) => {
    setLoginBody({ ...loginBody, [e.target.name]: e.target.value })
  }
  return (
    <div style={{ margin: "auto" }} className="section col-center rg-gap">
      <div className="col-center md-gap">
        <FormInput
          onInput={e => handleInput(e)}
          name="username"
          placeholder="اسم المستخدم"
        />

        <FormInput
          onInput={e => handleInput(e)}
          name="password"
          type="password"
          placeholder="كلمت السر"
        />
        <FormInput
          onInput={e => handleInput(e)}
          name="terminalId"
          placeholder="رقم المنفز"
        />
      </div>

      <Button onClick={() => login(loginBody)} variant="">
        تسجيل دخول
      </Button>
    </div>
  )
}

export default TerminalLogin
