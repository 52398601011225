import React, { useEffect, useState } from "react"

import { useTranslation } from "react-i18next"

import { useAppDispatch, useAppSelector } from "../../hooks"
import UnderDevelopment from "../../components/common/UnderDevelopment"
import OrderTable from "../../components/order/OrderTable"

import Template from "../../layout/Template"
import Header from "../../components/common/Header"
import FormInput from "../../components/common/FormInput"
import Analyse from "../../components/common/Analyse"
import {
  getOrdersReportThunk,
  getOrdersThunk
} from "../../features/order/thunk"
import FormSelect from "../../components/common/FormSelect"

function Orders() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { getOrders, ordersReport } = useAppSelector(state => state.order)

  const handleChange = (event: any) => {
    dispatch(
      getOrdersThunk({
        ...getOrders,
        [event.target.name]: event.target.value
      })
    )
    dispatch(
      getOrdersReportThunk({
        ...getOrders,
        [event.target.name]: event.target.value
      })
    )
  }

  useEffect(() => {
    dispatch(getOrdersThunk({ ...getOrders }))
    dispatch(getOrdersReportThunk({ ...getOrders }))
  }, [])

  return (
    <Template>
      <Header page_title={t("ordersPage.title")} />

      <div className="section col">
        <Analyse
          total_cost={ordersReport.report.expenses}
          total_price={ordersReport.report.revenue}
        />
      </div>

      <div className="section col container md-gap">
        <div className="row-center md-gap end">
          <FormSelect
            value={ordersReport.user}
            name="user"
            onChange={handleChange}
          >
            <option value="0">الكل</option>
            <option value="1">Omar Sabra</option>
            <option value="2">Mohsen Rabeh</option>
            <option value="3">Nour Zain</option>
            <option value="4">Shehab Ahmed</option>
            <option value="5">Rewan</option>
            <option value="6">Mariam</option>
          </FormSelect>
          <div className="row-center sm-gap">
            <p>من</p>
            <FormInput
              type="date"
              name="startDate"
              value={getOrders.startDate}
              onChange={handleChange}
            />
          </div>
          <div className="row-center sm-gap">
            <p>إلى</p>
            <FormInput
              name="endDate"
              type="date"
              value={getOrders.endDate}
              onChange={handleChange}
            />
          </div>
        </div>
        <OrderTable />
      </div>
    </Template>
  )
}

export default Orders
