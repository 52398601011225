import React from "react"
import { TerminalItemType } from "../../../features/item/initialState"
import Img, { ImageSize } from "../../common/Img"
import Price from "../../common/Price"
import Button from "../../common/Button"
import { getTerminalItem } from "../../../features/item/trunk"
import { useAppDispatch } from "../../../hooks"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { createCartItemThunk } from "../../../features/cart/thunk"
import { BiPlus } from "react-icons/bi"

function TerminalItemCard({ item }: { item: TerminalItemType }) {
  const dispatch = useAppDispatch()
  return (
    <div className="container sm-gap sm-width col">
      <Img imageSize={ImageSize.FIT} src={item.imagesUrl[0]} />
      <h2>{item.title}</h2>
      <Price className="rg-font" price={item.pricePerUnit} />
      <div className="row-center sm-gap">
        <Button
          onClick={() => dispatch(getTerminalItem(item.barcode))}
          variant="flex secondary"
        >
          عرض
        </Button>
        <Button
          onClick={() =>
            dispatch(
              createCartItemThunk({
                barcode: item.barcode,
                quantity: 1,
                quantityPerUnit: 1
              })
            )
          }
          variant="icon sm success"
        >
          <BiPlus />
        </Button>
      </div>
    </div>
  )
}

export default TerminalItemCard
