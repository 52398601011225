import React, { CSSProperties, useEffect } from "react"
import { TableRow } from "../common/Table"
import Price from "../common/Price"
import Counter from "./Counter"
import Button from "../common/Button"
import { FaTrashAlt } from "react-icons/fa"

import { useTranslation } from "react-i18next"

import { useAppDispatch, useAppSelector } from "../../hooks"
import Title from "../common/Title"
import {
  clearCartThunk,
  deleteCartItemThunk,
  updateCartItemThunk
} from "../../features/cart/thunk"
import { Unit } from "../../features/identity/initialState"
import FormInput from "../common/FormInput"
import { showAlert } from "../../features/appSlice"
import { AlertVariant } from "../../types/AlertType"
import { resetUpdateCartItem } from "../../features/cart/cartSlice"

function CartTable() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const {
    getCart: { cart },
    updateCartItem
  } = useAppSelector(state => state.cart)

  useEffect(() => {
    if (updateCartItem.errorMessage) {
      dispatch(
        showAlert({
          variant: AlertVariant.error,
          messageKey: updateCartItem.errorMessage
        })
      )
      dispatch(resetUpdateCartItem())
    }
  }, [updateCartItem.errorMessage])

  const tableTitle = {
    title: t("cartPage.cartItemsTitle"),
    quantity: t("itemDetails.quantity.title"),
    quantityPerUnit: "الوزن",
    price: t("itemDetails.pricePerUnit"),
    totalPrice: t("cartPage.totalPrice"),
    action: ""
  }

  const CSS: CSSProperties = {
    gridTemplateColumns: "4fr 2fr 2fr 2fr 2fr 1fr"
  }

  const tableRows =
    cart.cartItems &&
    cart.cartItems.map((cartItem: any, row_index) => {
      let modCartItem = { ...cartItem }

      const handleQuantityChange = (quantity: number) => {
        dispatch(
          updateCartItemThunk({
            id: cartItem.id,
            quantity
          })
        )
      }

      Object.keys(cartItem).forEach((key, index) => {
        switch (key) {
          case "price":
          case "totalPrice":
            modCartItem[key] = <Price price={cartItem[key]} />
            break

          case "quantityPerUnit":
            modCartItem[key] =
              cartItem.unit == Unit.KG ? (
                <span>{cartItem.quantityPerUnit} كجم</span>
              ) : (
                <span>لا يوجد</span>
              )
            break
          case "quantity":
            modCartItem[key] = (
              <Counter
                onChange={handleQuantityChange}
                value={cartItem.quantity}
              />
            )
            break

          default:
            modCartItem["action"] = (
              <Button
                onClick={() => dispatch(deleteCartItemThunk(modCartItem.id))}
                variant="outline danger icon sm"
              >
                <FaTrashAlt />
              </Button>
            )
            break
        }
      })

      return (
        <TableRow
          tableTitle={tableTitle}
          key={row_index}
          entity={modCartItem}
        />
      )
    })
  return (
    <div className="main container col rg-gap y-fit-content">
      <div className="wrapper row jc-sb row-center">
        <Title
          title={t("cartPage.cartItems")}
          description={t("cartPage.cartItemsSubtitle")}
        />
        <Button
          onClick={() => dispatch(clearCartThunk())}
          variant="outline danger"
          shortcut={"Delete"}
        >
          {t("cartPage.clearCartBtn")}
        </Button>
      </div>
      <div className="table">
        <div className="table__title" style={CSS}>
          <TableRow tableTitle={tableTitle} entity={tableTitle} />
        </div>
        <div className="table__content" style={CSS}>
          {tableRows}
        </div>
        <div className="table__footer">
          <div className="cell start--cell">{t("cartPage.subtotal")}</div>
          <div className="cell end--cell">
            {<Price price={cart.totalPrice} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartTable
