import React from "react"
import { Route, Routes } from "react-router-dom"
import CreateIdentity from "../templates/identity/CreateIdentity"
import Identities from "../templates/identity/Identities"
import Identity from "../templates/identity/Identity"
import UpdateIdentity from "../templates/identity/UpdateIdentity"

function IdentityRoutes() {
  return (
    <Routes>
      <Route path="" element={<Identities />} />
      <Route path="create" element={<CreateIdentity />} />
      <Route path=":id" element={<Identity />} />
      <Route path=":id/update" element={<UpdateIdentity />} />
    </Routes>
  )
}

export default IdentityRoutes
