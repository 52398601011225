import React from "react"
import Template from "../../layout/Template"
import Header from "../../components/common/Header"
import { useAppDispatch, useAppSelector } from "../../hooks"

import { getIdentities } from "../../features/identity/trunk"
import Button from "../../components/common/Button"
import FormInput from "../../components/common/FormInput"
import IdentityCard from "../../components/identity/read/IdentityCard"
import { resetGetIdentity } from "../../features/identity/identitySlice"
import { TableNavigator } from "../../components/common/ModTable"
import { BiSearch } from "react-icons/bi"
import BarcodeScanner from "../../components/common/BarcodeScanner"
import { MdRemove } from "react-icons/md"

function Identities() {
  const { identityTable, searchQuery, pageNumber, barcode } = useAppSelector(
    state => state.identity.getIdentities
  )
  const dispatch = useAppDispatch()

  const handleSearch = (e: any) => {
    dispatch(
      getIdentities({ pageNumber: 1, searchQuery: e.target.value, barcode })
    )
  }

  const handelBarcodeFilter = (scannedBarcode: string) => {
    dispatch(
      getIdentities({
        pageNumber: pageNumber,
        searchQuery: searchQuery,
        barcode: scannedBarcode
      })
    )
  }

  React.useEffect(() => {
    dispatch(
      getIdentities({
        pageNumber: pageNumber,
        searchQuery: searchQuery,
        barcode
      })
    )

    return () => {
      dispatch(resetGetIdentity())
    }
  }, [])

  return (
    <Template>
      <Header page_title="الاصناف" />

      <BarcodeScanner onScan={e => handelBarcodeFilter(e.value)} />
      <div className="section col rg-gap">
        <div className="row-center jc-sb">
          <div className="md-width">
            <FormInput
              defaultValue={searchQuery}
              unit={<BiSearch />}
              onInput={e => handleSearch(e)}
              placeholder="بحث في الهوايا"
            />
          </div>
          <div className="row-center md-gap">
            {barcode && (
              <div className="row-center sm-gap">
                <span
                  onClick={() => handelBarcodeFilter("")}
                  className="span-btn"
                >
                  <MdRemove />
                </span>
                <span>{barcode}</span>
              </div>
            )}
            <Button role="link" to="create" variant="">
              أضفف
            </Button>
          </div>
        </div>
        <div className="col md-gap">
          <div className="row-center jc-sb">
            <p>{`${identityTable.elements.length} هوايا من اصل ${identityTable.total_elements}`}</p>
            <TableNavigator
              {...identityTable}
              navigate={selectedPage =>
                dispatch(
                  getIdentities({
                    pageNumber: selectedPage,
                    searchQuery,
                    barcode
                  })
                )
              }
            />
          </div>
          <div className="md-gap wrap">
            {identityTable.elements.map(identity => (
              <IdentityCard key={identity.id} identity={identity} />
            ))}
          </div>
        </div>
      </div>
    </Template>
  )
}

export default Identities
