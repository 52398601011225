import React, { CSSProperties, useEffect } from "react"
import { useParams } from "react-router-dom"
import Header from "../../components/common/Header"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { getOrderThunk, returnOrderThunk } from "../../features/order/thunk"
import { OrderItem as OrderItemType } from "../../features/order/initialState"
import Price from "../../components/common/Price"
import { TableRow } from "../../components/common/Table"
import { Link } from "react-router-dom"
import Analyse from "../../components/common/Analyse"
import OrderItemTable from "../../components/order/OrderItemTable"
import { showAlert } from "../../features/appSlice"
import { AlertVariant } from "../../types/AlertType"
import { resetReturnOrder } from "../../features/order/orderSlice"
import Button from "../../components/common/Button"

function OrderItems({
  orderItems,
  tableTitle
}: {
  orderItems: OrderItemType[]
  tableTitle: Record<string, string>
}) {
  const tableRows = orderItems.map(order => {
    let orderItemRow: any = { ...order }
    Object.keys(orderItemRow).forEach(key => {
      switch (key) {
        case "totalPrice":
        case "totalCost":
        case "pricePerUnit":
        case "quantityPerUnit":
          orderItemRow[key] = <Price price={orderItemRow[key]} />
          break
        case "id":
          orderItemRow[key] = (
            <Link to={`${orderItemRow["id"]}`}>#{orderItemRow["id"]}</Link>
          )
          break

        default:
          orderItemRow["profit"] = (
            <Price
              price={orderItemRow["totalPrice"] - orderItemRow["totalCost"]}
            />
          )
      }
    })

    return (
      <TableRow
        key={orderItemRow.id}
        tableTitle={tableTitle}
        entity={orderItemRow}
      />
    )
  })

  return { ...tableRows }
}

function Order() {
  const { id } = useParams() as { id: string }
  const {
    getOrder,
    returnOrder: { errorMessage, statusCode }
  } = useAppSelector(state => state.order)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(getOrderThunk({ id }))
  }, [])

  useEffect(() => {
    if (errorMessage) {
      dispatch(
        showAlert({
          variant: AlertVariant.error,
          messageKey: errorMessage
        })
      )

      dispatch(resetReturnOrder())
    }

    if (statusCode == 200) {
      dispatch(
        showAlert({
          variant: AlertVariant.success,
          messageKey: "تم استرجاع الطلب بنجاح"
        })
      )
      dispatch(resetReturnOrder())
    }
  }, [statusCode])

  return (
    <div>
      <Header page_title={t("ordersPage.title")} subTitle={`#${id}`} />
      <div className="section col">
        <Analyse
          total_cost={getOrder.order.totalCost}
          total_price={getOrder.order.totalPrice}
        />
        <div className="container col md-gap">
          <div className="end">
            <Button
              variant=""
              onClick={() => {
                dispatch(returnOrderThunk({ id: getOrder.order.id }))
              }}
            >
              ارجاع
            </Button>
          </div>
          <OrderItemTable
            orderItems={getOrder.order.orderItems}
            totalPrice={getOrder.order.totalPrice}
          />
        </div>
      </div>
    </div>
  )
}

export default Order
