import { createAsyncThunk } from "@reduxjs/toolkit";
import FetchWrapper from "../../../utils/FetchWrapper";
import { Category } from "../initialState";
import { HttpStatusCode } from "axios";
import { showAlert } from "../../appSlice";
import { AlertVariant } from "../../../types/AlertType";
import { CreateCategory } from "./createCategory";

type UpdateCategory = {
  id: number;
  category: CreateCategory;
};

const updateCategory = createAsyncThunk(
  "category/updateCategory",
  async ({ id, category }: UpdateCategory, { dispatch }) => {
    const categoryRes = await FetchWrapper.put(`/category/${id}`, category);

    const updatedCategory = categoryRes.json();
    if (categoryRes.status == HttpStatusCode.Ok) {
      dispatch(
        showAlert({
          variant: AlertVariant.success,
          messageKey: "common.alert.message.updateCategorySuccess"
        })
      );
      return updatedCategory;
    } else {
      dispatch(showAlert({ variant: AlertVariant.error, messageKey: "" }));
    }
  }
);

export default updateCategory;
