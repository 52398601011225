import { ItemType } from "../item/initialState"

const initialState = {
  getCart: {
    statusCode: 0,
    cart: {
      id: 0,
      totalPrice: 0,
      cartItems: []
    }
  },

  updateCartItem: {
    statusCode: 0,
    cartItem: { quantity: 1 },
    errorMessage: ""
  },
  createCartItem: {
    cartItem: { quantityPerUnit: 1, quantity: 1, barcode: "" },
    statusCode: 0,
    errorMessage: ""
  },
  checkoutCart: {
    statusCode: 0
  }
}

export type CartState = typeof initialState

export type CreateCartItemState = typeof initialState.createCartItem
export type CreateCartItem = typeof initialState.createCartItem.cartItem
export type UpdateCartItem = typeof initialState.updateCartItem.cartItem
export default initialState
