import { createAsyncThunk } from "@reduxjs/toolkit";
import FetchWrapper from "../../../utils/FetchWrapper";
import { Category } from "../initialState";
import { HttpStatusCode } from "axios";

const getCategory = createAsyncThunk(
  "category/getCategory",
  async (id: number) => {
    const categoryRes = await FetchWrapper.get(`/category/${id}`);

    const category = categoryRes.json();
    if (categoryRes.status == HttpStatusCode.Ok) {
      return category;
    }
  }
);

export default getCategory;
