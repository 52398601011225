import React, { useEffect } from "react"
import Template from "../../layout/Template"
import Alert from "../../components/common/Alert"
import PageHeader from "../../components/common/Header"
import UnpackItemForm from "../../components/items/edit/unpackItemForm"
import { useAppDispatch } from "../../hooks"

function Unpack() {
  const dispatch = useAppDispatch()
  useEffect(() => {
    return () => {
      // dispatch(restQuantity());
    }
  })
  return (
    <Template>
      <PageHeader page_title={"فتح"} />
      <UnpackItemForm />
      <Alert />
    </Template>
  )
}

export default Unpack
