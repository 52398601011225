import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import extraReducers from "./extraReducers"
import initialState, { CreateItemType } from "./initialState"

export const itemSlice = createSlice({
  name: "item",
  initialState,
  reducers: {
    setItem: (state, action: PayloadAction<CreateItemType>) => {
      state.createItem.item = action.payload
    },

    resetItem: state => {
      state.createItem = initialState.createItem
    },

    setUpdateItem: (
      state,
      action: PayloadAction<typeof initialState.updateItem>
    ) => {
      state.updateItem = action.payload
    },

    resetUpdateItem: state => {
      state.updateItem = initialState.updateItem
    },
    resetErrorMessage: state => {
      state.createItem.errorMessage = initialState.createItem.errorMessage
    },

    resetGetItem: state => {
      state.getItem = initialState.getItem
    },

    resetGetTerminalItem: state => {
      state.getTerminalItem = initialState.getTerminalItem
    },
    resetGetItems: state => {
      state.getItems = initialState.getItems
    },
    resetGetTerminalItems: state => {
      state.getTerminalItems = initialState.getTerminalItems
    }
  },

  extraReducers
})

export const {
  setItem,
  resetErrorMessage,
  resetItem,
  resetGetItem,
  resetGetItems,
  resetUpdateItem,
  resetGetTerminalItem,
  resetGetTerminalItems,
  setUpdateItem
} = itemSlice.actions

export default itemSlice.reducer
