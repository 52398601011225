import React, { useEffect } from "react"
import Model from "../common/Model"

import { useAppDispatch, useAppSelector } from "../../hooks"

import { resetGetTerminalItem } from "../../features/item/itemSlice"
import { showAlert, showModel } from "../../features/appSlice"
import IdentityGallery from "../identity/read/IdentityGallery"
import { ImageSize } from "../common/Img"
import Price from "../common/Price"
import { AlertVariant } from "../../types/AlertType"
import Button from "../common/Button"
import FormInput from "../common/FormInput"
import { Unit } from "../../features/identity/initialState"
import { createCartItemThunk } from "../../features/cart/thunk"
import { resetCreateCartItem, setCartItem } from "../../features/cart/cartSlice"
import Utils from "../../utils/Utils"

function TerminalItemQuickViewModel() {
  const { statusCode, item } = useAppSelector(
    state => state.item.getTerminalItem
  )
  const dispatch = useAppDispatch()
  const { createCartItem } = useAppSelector(state => state.cart)

  const onChange = (e: any) => {
    dispatch(
      setCartItem({
        ...createCartItem.cartItem,
        [e.target.name]: e.target.value
      })
    )
  }

  useEffect(() => {
    if (statusCode == 200) {
      dispatch(
        setCartItem({
          ...createCartItem.cartItem,
          barcode: item.barcode
        })
      )
      dispatch(showModel())
    }

    if (createCartItem.statusCode == 201) {
      dispatch(
        showAlert({
          variant: AlertVariant.success,
          messageKey: "المنتج اتضاف للعربة"
        })
      )
      dispatch(resetCreateCartItem())
    }

    if (createCartItem.statusCode == 409) {
      dispatch(
        showAlert({
          variant: AlertVariant.error,
          messageKey: "مفيش كمية كفاية"
        })
      )
      dispatch(resetCreateCartItem())
    }
    if (statusCode == 404) {
      dispatch(
        showAlert({
          variant: AlertVariant.error,
          messageKey: "المنتج ده مش موجود"
        })
      )
      dispatch(resetGetTerminalItem())
    }
  }, [statusCode, createCartItem.statusCode])

  return (
    <Model
      onExited={() => {
        dispatch(resetCreateCartItem())
        dispatch(resetGetTerminalItem())
      }}
    >
      <div className="container row rg-gap">
        <div className="col-center">
          <IdentityGallery
            imageUrls={item.imagesUrl}
            imageSize={ImageSize.MEDUIM}
          />
        </div>
        <div className="col  flex width-300 jc-sb">
          <div className="col md-gap">
            <div className="col sm-gap">
              <p>الاسم</p>
              <h1>{item.title}</h1>
            </div>
            <div className="col sm-gap">
              <p>السعر</p>
              <Price className="lg-font" price={item.pricePerUnit} />
            </div>
          </div>
          <div className="col md-gap">
            {item.unit == Unit.KG && (
              <div className="row-center md-gap">
                <FormInput
                  label={"السعر"}
                  placeholder="السعر المطلوب"
                  name="totalPrice"
                  unit={"ج.م"}
                  type="number"
                  value={Utils.round(
                    createCartItem.cartItem.quantityPerUnit *
                      createCartItem.cartItem.quantity *
                      item.pricePerUnit
                  )}
                  onInput={(e: any) => {
                    dispatch(
                      setCartItem({
                        ...createCartItem.cartItem,
                        quantityPerUnit: e.target.value / item.pricePerUnit
                      })
                    )
                  }}
                />
                <FormInput
                  label={"الوزن"}
                  placeholder="الوزن بالكيلوجرام"
                  name="quantityPerUnit"
                  unit={"كجم"}
                  type="number"
                  value={Utils.round(createCartItem.cartItem.quantityPerUnit)}
                  onInput={onChange}
                />
              </div>
            )}
            <div className="row-center sm-gap">
              <Button
                onClick={() =>
                  dispatch(
                    createCartItemThunk({
                      ...createCartItem.cartItem
                    })
                  )
                }
                variant="flex success"
              >
                اضف الى العربة
              </Button>
              <div className="row-center xsm-width">
                <FormInput
                  type="number"
                  name="quantity"
                  onInput={onChange}
                  value={createCartItem.cartItem.quantity}
                  placeholder="الكمية"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Model>
  )
}

export default TerminalItemQuickViewModel
