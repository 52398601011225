import { CSSTransition } from "react-transition-group"
import "./styles/Alert.css"
import React, { useEffect } from "react"
import { BsCheckCircleFill } from "react-icons/bs"
import { TiDelete, TiWarning } from "react-icons/ti"
import { MdError } from "react-icons/md"
import { useTranslation } from "react-i18next"
import { BsFillInfoCircleFill } from "react-icons/bs"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { errorAlert, successAlert } from "../../assets/sounds"
import { AlertVariant } from "../../types/AlertType"
import { hideAlert } from "../../features/appSlice"

function Alert() {
  const { t } = useTranslation()
  const { variant, isVisible, messageKey } = useAppSelector(
    state => state.app.appAlert
  )
  const dispatch = useAppDispatch()

  const getAlertInfo = (variant: AlertVariant) => {
    if (variant === AlertVariant.success) {
      return {
        icon: <BsCheckCircleFill />,
        message: t("common.alert.success"),
        audio: new Audio(successAlert)
      }
    }
    if (variant === AlertVariant.warning) {
      return {
        icon: <TiWarning />,
        message: t("common.alert.warning"),
        audio: new Audio(errorAlert)
      }
    }
    if (variant === AlertVariant.info) {
      return {
        icon: <BsFillInfoCircleFill />,
        message: t("common.alert.info"),
        audio: new Audio(successAlert)
      }
    } else {
      return {
        icon: <MdError />,
        message: t("common.alert.error"),
        audio: new Audio(errorAlert)
      }
    }
  }

  const alertInfo = getAlertInfo(variant)

  useEffect(() => {
    if (isVisible) {
      alertInfo.audio.play()
      //AUTO HIDE ALERT
      const timeoutId = setTimeout(() => {
        dispatch(hideAlert())
      }, 5000)

      //CLEAN
      return () => {
        dispatch(hideAlert())
        clearTimeout(timeoutId)
      }
    }
  }, [isVisible])

  return (
    <CSSTransition
      in={isVisible}
      timeout={300}
      classNames="slide"
      unmountOnExit
    >
      <section
        className={`alert container row-center md-padding md-gap ${AlertVariant[variant]}`}
      >
        <div className="alert__icon row-center">{alertInfo.icon}</div>
        <div className="alert__text">
          <h2>{alertInfo.message}</h2>
          <span className="alert__message ">{t(messageKey)}</span>
        </div>
        <div
          onClick={() => dispatch(hideAlert())}
          className="alert__exit row-center svg sm"
        >
          <TiDelete />
        </div>
      </section>
    </CSSTransition>
  )
}

export default Alert
