import { StatusCodes } from "http-status-codes"

import TableType from "../../types/TableType"
import { Category, Unit, identity } from "../identity/initialState"

export enum ItemStatusType {
  ACTIVE = "ACTIVE",
  DRAFT = "DRAFT",
  AUTO_DRAFT = "AUTO_DRAFT",
  UNAVAILABLE = "UNAVAILABLE"
}

const itemInitState = {
  id: 0,
  pricePerUnit: ("" as unknown) as number,
  costPerUnit: ("" as unknown) as number,
  quantity: ("" as unknown) as number,
  costPerQuantity: ("" as unknown) as number,
  originId: "",
  itemStatus: ItemStatusType.ACTIVE,
  identity: identity
}
export type ItemType = typeof itemInitState

const terminalItemInitialState = {
  id: 0,
  title: "",
  categories: [] as Array<Category>,
  barcode: "",
  description: "",
  autoGenerateBarcode: false,
  imagesUrl: [],
  unit: Unit.PC,
  quantityPerUnit: 1,
  pricePerUnit: ("" as unknown) as number,
  itemStatus: ItemStatusType.ACTIVE
}
export type TerminalItemType = typeof terminalItemInitialState

const createItemInitState = {
  barcode: "",
  costPerUnit: ("" as unknown) as number,
  pricePerUnit: ("" as unknown) as number,
  quantity: ("" as unknown) as number,
  costPerQuantity: ("" as unknown) as number,
  originId: "1",
  itemStatus: ItemStatusType.ACTIVE
}
export type CreateItemType = typeof createItemInitState

const updateItemInitState = {
  costPerUnit: ("" as unknown) as number,
  pricePerUnit: ("" as unknown) as number,
  quantity: ("" as unknown) as number,
  costPerQuantity: ("" as unknown) as number,
  itemStatus: ItemStatusType.ACTIVE
}
export type UpdateItemType = typeof updateItemInitState

const initialState = {
  getItem: {
    item: itemInitState,
    statusCode: 0
  },

  getTerminalItem: {
    item: terminalItemInitialState,
    statusCode: 0
  },

  unpackItem: {
    errorMessage: "",
    item: itemInitState,
    statusCode: 0
  },

  createItem: {
    errorMessage: "",
    statusCode: 0,
    item: createItemInitState,
    itemFieldError: {} as any
  },

  updateItem: {
    errorMessage: "",
    statusCode: 0,
    item: updateItemInitState,
    itemFieldError: {} as any
  },

  getItems: {
    itemsTable: {
      current_page: 1,
      total_pages: 0,
      elements_per_page: 0,
      total_elements: 0,
      remaining_elements: 0,
      elements: [] as Array<typeof itemInitState>
    } as TableType<typeof itemInitState>,
    searchQuery: "",
    itemStatus: "" as ItemStatusType,
    barcode: "",
    pageNumber: 1
  },

  getTerminalItems: {
    itemsTable: {
      current_page: 1,
      total_pages: 0,
      elements_per_page: 0,
      total_elements: 0,
      remaining_elements: 0,
      elements: [] as Array<typeof terminalItemInitialState>
    } as TableType<typeof terminalItemInitialState>,
    searchQuery: "",
    pageNumber: 1
  }
}

export type ItemState = typeof initialState
export type GetItemResponse = typeof initialState.getItem
export type CreateItemResponse = typeof initialState.createItem

export default initialState
