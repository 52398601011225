import React from "react"
import { TableRow } from "../common/Table"
import { useTranslation } from "react-i18next"
import Price from "../common/Price"
import DateFormatter from "../common/DateFormatter"
import { TableNavigator } from "../common/ModTable"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { getOrdersThunk } from "../../features/order/thunk"
import { Link } from "react-router-dom"

function OrderTable() {
  const { t } = useTranslation()

  const { getOrders } = useAppSelector(state => state.order)
  const gridTemplateColumns = "1fr 1fr 1fr 1fr 2fr"
  const tableTitle = {
    id: t("orderDetails.orderId"),
    totalPrice: t("orderDetails.totalPrice"),
    totalCost: t("orderDetails.totalCost"),
    profit: t("orderDetails.profit"),
    dateCreated: t("orderDetails.date")
  }
  const dispatch = useAppDispatch()

  const tableRows =
    getOrders.ordersTable.elements.length != 0 &&
    getOrders.ordersTable.elements.map(order => {
      let modOrder: any = { ...order }

      Object.keys(modOrder).forEach(key => {
        switch (key) {
          case "totalPrice":
          case "totalCost":
            modOrder[key] = <Price price={modOrder[key]} />
            break
          case "dateCreated":
            modOrder[key] = <DateFormatter date={modOrder[key]} />
            break
          case "id":
            modOrder[key] = (
              <Link to={`${modOrder["id"]}`}>#{modOrder["id"]}</Link>
            )
            break
        }
        modOrder["profit"] = (
          <Price price={order.totalPrice - order.totalCost} />
        )
      })

      return (
        <TableRow key={modOrder.id} tableTitle={tableTitle} entity={modOrder} />
      )
    })

  return (
    <div className="table">
      <div className="table__title" style={{ gridTemplateColumns }}>
        <TableRow tableTitle={tableTitle} entity={tableTitle} />
      </div>
      <div className="table__content" style={{ gridTemplateColumns }}>
        {tableRows}
      </div>
      <div className="table__footer">
        <div className="row-wrapper">
          <div className="cell start--cell">
            {`${getOrders.ordersTable.elements.length} طلب من اصل ${getOrders.ordersTable.total_elements}`}
          </div>
          <div className="cell end--cell">
            {
              <TableNavigator
                {...getOrders.ordersTable}
                navigate={page =>
                  dispatch(getOrdersThunk({ ...getOrders, pageNumber: page }))
                }
              />
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderTable
