import React, { useEffect, useState } from "react"
import PageHeader from "../../components/common/Header"
import { useTranslation } from "react-i18next"
import Button from "../../components/common/Button"
import Template from "../../layout/Template"
import ItemsTable from "../../components/items/read/ItemsTable"
import FormInput from "../../components/common/FormInput"
import { useAppDispatch, useAppSelector } from "../../hooks"
import BarcodeScanner from "../../components/common/BarcodeScanner"
import FormSelect from "../../components/common/FormSelect"
import { BiSearch } from "react-icons/bi"
import { getItems as getItemsRequest } from "../../features/item/trunk"
import { ItemStatusType } from "../../features/item/initialState"
import { MdRemove } from "react-icons/md"
import { resetGetItems } from "../../features/item/itemSlice"

function Items() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { getItems } = useAppSelector(state => state.item)

  useEffect(() => {
    dispatch(
      getItemsRequest({
        ...getItems
      })
    )

    return () => {
      resetGetItems()
    }
  }, [])

  const handleSearch = (searchQuery: string) => {
    dispatch(
      getItemsRequest({
        ...getItems,
        searchQuery
      })
    )
  }

  const handelBarcodeFilter = (barcode: string) => {
    dispatch(
      getItemsRequest({
        ...getItems,
        barcode
      })
    )
  }

  const handelItemStatusFilter = (itemStatus: ItemStatusType) => {
    dispatch(
      getItemsRequest({
        ...getItems,
        itemStatus
      })
    )
  }
  return (
    <Template>
      <PageHeader page_title={t("itemsPage.title")} />
      <BarcodeScanner onScan={barcode => handelBarcodeFilter(barcode.value)} />
      <section className="section container md-gap col">
        <div className="wrapper row-center jc-sb">
          <div className="row md-width">
            <FormInput
              placeholder={`${t("itemsPage.searchItems")}`}
              unit={<BiSearch />}
              defaultValue={getItems.searchQuery}
              onInput={e =>
                handleSearch(
                  //@ts-ignore
                  e.target.value
                )
              }
            />
          </div>
          <div className="row-center rg-gap">
            <div className="row-center md-gap">
              <FormSelect
                onChange={(e: any) => handelItemStatusFilter(e.target.value)}
                value={getItems.itemStatus}
              >
                <option value="">الكل</option>
                <option value="ACTIVE">نشطه</option>
                <option value="DRAFT">مسوده</option>
                <option value="AUTO_DRAFT">مسوده تلقائية</option>
                <option value="UNAVAILABLE">غير متوفرة</option>
              </FormSelect>
              {getItems.barcode && (
                <div className="row-center sm-gap">
                  <span
                    onClick={() => handelBarcodeFilter("")}
                    className="span-btn"
                  >
                    <MdRemove />
                  </span>
                  <span>{getItems.barcode}</span>
                </div>
              )}
            </div>
            <Button
              variant="y-fit-content"
              to="create"
              role="link"
              shortcut={"+"}
            >
              {t("itemsPage.addItemBtn")}
            </Button>
          </div>
        </div>
        <ItemsTable />
      </section>
    </Template>
  )
}

export default Items
