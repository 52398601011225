import React from "react"
import Template from "../../layout/Template"
import PageHeader from "../../components/common/Header"
import FormInput from "../../components/common/FormInput"
import FormSelect from "../../components/common/FormSelect"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { createCategory, getCategories } from "../../features/category/tunk"
import {
  restCategories,
  setCategory
} from "../../features/category/categorySlice"
import Button from "../../components/common/Button"

function AddCategory() {
  const { categories, category } = useAppSelector(state => state.category)
  const dispatch = useAppDispatch()

  console.log("Rendered")
  const handelInput = (
    e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
  ) => {
    //@ts-ignore
    console.log(e.target.name, e.target.value)
    dispatch(
      setCategory({
        ...category,
        //@ts-ignore
        [e.target.name]: e.target.value
      })
    )
  }

  React.useEffect(() => {
    dispatch(getCategories(false))

    return () => {
      dispatch(restCategories())
    }
  }, [])
  return (
    <Template>
      <PageHeader page_title="اضف" />
      <section className="section center row">
        <div className="container col aside rg-gap">
          <FormInput
            label={"الاسم"}
            value={category.name}
            name="name"
            onInput={handelInput}
          />
          <FormSelect
            onChange={handelInput}
            value={category.parentId || ""}
            name="parentId"
            label={"التصنيف الفرعي"}
          >
            <option key={0} value={""}>
              مش موجود
            </option>
            {categories.map(cat => (
              <option key={cat.id} value={cat.id}>
                {cat.name}
              </option>
            ))}
          </FormSelect>
          <Button
            onClick={() => dispatch(createCategory(category))}
            variant="end"
          >
            أضف
          </Button>
        </div>
      </section>
    </Template>
  )
}

export default AddCategory
