import React, { useEffect } from "react"
import Button from "../common/Button"
import Price from "../common/Price"
import { useTranslation } from "react-i18next"
import { useAppSelector, useAppDispatch } from "../../hooks"
import Title from "../common/Title"
import { checkoutCartThunk } from "../../features/cart/thunk"
import { showAlert } from "../../features/appSlice"
import { AlertVariant } from "../../types/AlertType"
import { resetCheckoutCart } from "../../features/cart/cartSlice"

function CartSummary() {
  const { t } = useTranslation()
  const {
    getCart: { cart },
    checkoutCart: { statusCode }
  } = useAppSelector(state => state.cart)

  useEffect(() => {
    if (statusCode == 201) {
      dispatch(
        showAlert({
          variant: AlertVariant.success,
          messageKey: "common.alert.message.addOrderSuccess"
        })
      )
      dispatch(resetCheckoutCart())
    }
  }, [statusCode])
  const dispatch = useAppDispatch()
  return (
    <div className="aside container fit-height col md-gap">
      <Title title={t("cartPage.cartSummary")} />
      <div className="wrapper col md-gap">
        <div className="wrapper col sm-gap">
          <li>{t("cartPage.totalPrice")}</li>
          <Price className="lg-font" price={cart.totalPrice} />
        </div>
        <Button
          shortcut="Enter"
          onClick={() => dispatch(checkoutCartThunk())}
          variant=""
        >
          {t("cartPage.checkoutBtn")}
        </Button>
      </div>
    </div>
  )
}

export default CartSummary
