import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import initialState, { CategoryState } from "./initialState";
import { createCategory, deleteCategory, getCategories } from "./tunk";
import getCategory from "./tunk/getCategory";
import updateCategory from "./tunk/updateCategory";

const extraReducers = (builder: ActionReducerMapBuilder<CategoryState>) => {
  builder
    .addCase(getCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
      state.status.statusCode = 200;
      state.status.isLoading = false;
    })
    .addCase(createCategory.fulfilled, (state, action) => {
      if (action.payload) {
        state.category = initialState.category;
        state.status.statusCode = 201;
        state.status.isLoading = false;
      }
    })
    .addCase(getCategory.fulfilled, (state, action) => {
      if (action.payload) {
        state.category = action.payload;
        state.status.statusCode = 200;
        state.status.isLoading = false;
      } else {
        state.status.statusCode = 404;
        state.status.isLoading = false;
      }
    });
};

export default extraReducers;
