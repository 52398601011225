import React, { useState, useEffect } from "react"
import Button from "./Button"

import { AiOutlineCopy } from "react-icons/ai"
import { FiDownload } from "react-icons/fi"
import { useAppDispatch } from "../../hooks"
import { setAlert, showAlert } from "../../features/appSlice"
import { AlertVariant } from "../../types/AlertType"
import FetchWrapper from "../../utils/FetchWrapper"
import Img from "./Img"
import { gearGif } from "../../assets/images"

type Props = {
  barcode: string
  title: string
}

function Barcode({ barcode, title }: Props) {
  const dispatch = useAppDispatch()
  const [barcodeSrc, setBarcodeSrc] = useState(gearGif)

  useEffect(() => {
    const updateBarcode = async () => {
      const res = await FetchWrapper.get(`/barcode/${barcode}`)
      const body = await res.json()
      console.log(body)
      const src = body.url
      setBarcodeSrc(src)
    }

    if (barcode) updateBarcode()
  }, [barcode])

  const handleCopyBarcode = () => {
    navigator.clipboard.writeText(barcode)
    dispatch(
      showAlert({
        variant: AlertVariant.success,
        messageKey: "common.alert.message.barcodeCopied"
      })
    )
  }

  return (
    <div className="row md-gap">
      <img
        className="animation--fade-in"
        height={82}
        style={{
          background: "white",
          borderRadius: 5,
          padding: 5,
          transition: "300ms"
        }}
        src={barcodeSrc}
      />
      <div className="col sm-gap">
        <Button
          variant="icon success"
          to={barcodeSrc}
          role="link"
          download={`${title}.svg`}
          target="blank"
        >
          <FiDownload />
        </Button>
        <Button
          variant="icon outline"
          role="link"
          onClick={() => handleCopyBarcode()}
        >
          <AiOutlineCopy />
        </Button>
      </div>
    </div>
  )
}

export default Barcode
