import React from "react"
import { useTranslation } from "react-i18next"
import PageHeader from "../components/common/Header"
import Template from "../layout/Template"
import UnderDevelopment from "../components/common/UnderDevelopment"
function Home() {
  const { t } = useTranslation()
  return (
    // <Template>
    //   <PageHeader page_title={t("homePage.title")} header_cta={<></>} />
    //   <div style={{ margin: "auto" }} className="section col-center ">
    //     <h1>UNDER DEVELOPMENT</h1>
    //   </div>
    // </Template>

    <UnderDevelopment />
  )
}

export default Home
