import React from "react"
import { TableRow } from "../common/Table"
import Price from "../common/Price"
import { Link } from "react-router-dom"
import { OrderItem } from "../../features/order/initialState"

type Props = {
  orderItems: OrderItem[]
  totalPrice?: number
}

function OrderItemTable({ orderItems, totalPrice }: Props) {
  const gridTemplateColumns = "3.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"

  const tableTitle = {
    title: "العنوان",
    pricePerUnit: "سعر الوحدة",
    costPerUnit: "تكلفة الوحدة",
    quantity: "الكمية",
    quantityPerUnit: "الوزن",
    totalPrice: "اجمالي السعر",
    totalCost: "اجمالي التكلفة",
    profit: "الارباح"
  }

  const orderItemsTableRows = orderItems.map(orderItem => {
    let orderItemRow: any = { ...orderItem }
    Object.keys(orderItemRow).forEach(key => {
      switch (key) {
        case "totalPrice":
        case "totalCost":
        case "pricePerUnit":
        case "costPerUnit":
          orderItemRow[key] = <Price price={orderItemRow[key]} />
          break
        case "quantityPerUnit":
          orderItemRow[key] =
            orderItemRow["unit"] == "KG"
              ? `${orderItemRow[key]} كجم`
              : "لا يوجد"
          break
        case "title":
          orderItemRow[key] = (
            <Link to={`/app/item/${orderItem.itemId}`}>{orderItem.title}</Link>
          )
          break
        case "id":
          orderItemRow[key] = (
            <Link to={`${orderItemRow["id"]}`}>#{orderItemRow["id"]}</Link>
          )
          break
      }

      orderItemRow["profit"] = (
        <Price price={orderItem.pricePerUnit - orderItem.costPerUnit} />
      )
    })

    return (
      <TableRow
        key={orderItem.id}
        tableTitle={tableTitle}
        entity={orderItemRow}
      />
    )
  })

  return (
    <div className="table">
      <div className="table__title" style={{ gridTemplateColumns }}>
        <TableRow tableTitle={tableTitle} entity={tableTitle} />
      </div>
      <div className="table__content" style={{ gridTemplateColumns }}>
        {orderItemsTableRows}
      </div>

      <div className="table__footer">
        <div className="row-wrapper">
          <div className="cell start--cell">الأجمالي</div>
          <div className="cell end--cell">
            {totalPrice && <Price price={totalPrice} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderItemTable
