import React, { useEffect } from "react"
import Template from "../../layout/Template"
import PageHeader from "../../components/common/Header"
import { useTranslation } from "react-i18next"
import TerminalItemQuickViewModel from "../../components/model/TerminalItemQuickViewModel"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { getTerminalItem, getTerminalItems } from "../../features/item/trunk"
import BarcodeScanner from "../../components/common/BarcodeScanner"
import FormInput from "../../components/common/FormInput"
import { BiSearch } from "react-icons/bi"
import TerminalItemCard from "../../components/items/read/TerminalItemCard"
import { TableNavigator } from "../../components/common/ModTable"
import {
  resetGetTerminalItem,
  resetGetTerminalItems
} from "../../features/item/itemSlice"

function TerminalItems() {
  const { t } = useTranslation()
  const { searchQuery, pageNumber, itemsTable } = useAppSelector(
    state => state.item.getTerminalItems
  )
  const dispatch = useAppDispatch()

  const handleSearch = (e: any) => {
    dispatch(getTerminalItems({ pageNumber, searchQuery: e.target.value }))
  }

  useEffect(() => {
    dispatch(getTerminalItems({ searchQuery, pageNumber }))

    return () => {
      dispatch(resetGetTerminalItems())
    }
  }, [])

  return (
    <Template>
      <PageHeader page_title={t("itemsPage.title")} />
      <TerminalItemQuickViewModel />
      <BarcodeScanner onScan={e => dispatch(getTerminalItem(e.value))} />

      <div className="section col md-gap">
        <div className="md-width">
          <FormInput
            defaultValue={searchQuery}
            unit={<BiSearch />}
            onInput={handleSearch}
            placeholder="بحث في المنتجات"
          />
        </div>
        <div className="row-center jc-sb">
          <p>{`${itemsTable.elements.length} منتج من اصل ${itemsTable.total_elements}`}</p>
          <TableNavigator
            {...itemsTable}
            navigate={page =>
              dispatch(getTerminalItems({ pageNumber: page, searchQuery }))
            }
          />
        </div>
        <div className="wrap md-gap">
          {itemsTable.elements.map((item, index) => (
            <TerminalItemCard key={index} item={item} />
          ))}
        </div>
      </div>
    </Template>
  )
}

export default TerminalItems
