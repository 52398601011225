import React from "react"
import Img, { ImageSize } from "../../common/Img"
import { IdentityType } from "../../../features/identity/initialState"
import { logo_png, shoppingGif } from "../../../assets/images"
import { Link } from "react-router-dom"

function IdentityCard({ identity }: { identity: IdentityType }) {
  return (
    <div className="col container md-gap sm-width">
      <Img
        style={{ background: "white" }}
        src={identity.imageUrls[0]}
        imageSize={ImageSize.FIT}
      />

      <Link to={`${identity.id}`} className="row flex">
        {identity.title}
      </Link>
    </div>
  )
}

export default IdentityCard
