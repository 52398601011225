import React, { FormEvent } from "react"

import FormInput from "../../common/FormInput"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { setItem } from "../../../features/item/itemSlice"

function ItemPricePerUnit() {
  const { t } = useTranslation()
  const { item } = useAppSelector(state => state.item.createItem)
  const { itemFieldError } = useAppSelector(state => state.item.createItem)
  const { identity } = useAppSelector(state => state.identity.getIdentity)
  const dispatch = useAppDispatch()

  const onChange = (pricePerUnit: number) => {
    dispatch(
      setItem({
        ...item,
        pricePerUnit
      })
    )
  }

  return (
    <div className="container">
      <FormInput
        label={t("itemDetails.pricePerUnit")}
        name="title"
        placeholder={
          (t(
            "itemForm.pricePerUnitPlaceholder." + identity.unit
          ) as unknown) as string
        }
        onInput={e => {
          // @ts-ignore
          onChange(e.target.value)
        }}
        value={item.pricePerUnit}
        error={itemFieldError.pricePerUnit}
      />
    </div>
  )
}

export default ItemPricePerUnit
