import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import type { RootState } from "./store";
import AlertType, { AlertVariant } from "../types/AlertType";
import { ReactNode } from "react";

const alertInitState: AlertType = {
  isVisible: false,
  messageKey: "",
  variant: AlertVariant.success
};

type ShowAlert = {
  messageKey: string
  variant: AlertVariant
}


const barcodeScannerInitStat = {
  scannedBarcode: "",
  isScanning: false,
  isVisible: false
}


const modelInitState = {
  isVisible: false,
}

const fileUploadState = {
  message: "",
  loading: false,
  progress: 0
}

export type FileUploadState = typeof fileUploadState
const initialState = {
  appAlert: alertInitState,
  barcodeScanner: barcodeScannerInitStat,
  language: localStorage.getItem("language") || "ar",
  model: modelInitState,
  fileUploadState
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<AlertType>) => {
      state.appAlert = action.payload;
    },
    setVisible: (state, action: PayloadAction<boolean>) =>{
      state.barcodeScanner.isVisible = action.payload
    },
    hideAlert: (state) =>{
      state.appAlert = alertInitState
    },
    showAlert: (state, action: PayloadAction<ShowAlert>) =>{
      state.appAlert = {...action.payload, isVisible: true}
    },
    setScannedBarcode: (state, action: PayloadAction<string>) =>{
      state.barcodeScanner.scannedBarcode = action.payload
    },
    setScanning: (state, action: PayloadAction<boolean>) =>{
      state.barcodeScanner.isScanning = action.payload
    },
    showModel: (state) =>{
      state.model.isVisible = true
     
    },
    hideModel: (state) =>{
      state.model = modelInitState
    },
    resetBarcodeScanner: (state) =>{
      state.barcodeScanner = initialState.barcodeScanner
    },
    setFileUploadState: (state, action: PayloadAction<FileUploadState> ) =>{
      state.fileUploadState =action.payload
    },
    resetFileUploadState: (state) =>{
      state.fileUploadState = fileUploadState
    }
   
  },
});

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.app;
export const { setAlert, setVisible, setScannedBarcode, setScanning, hideAlert,setFileUploadState,  showAlert, showModel, hideModel, resetBarcodeScanner} = appSlice.actions;

export default appSlice.reducer;
