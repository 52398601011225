import React from "react"
import "./styles/Table.css"
import TableType from "../../types/TableType"
import Button from "./Button"
import {
  MdOutlineNavigateBefore,
  MdOutlineNavigateNext,
  MdOutlineMoreHoriz
} from "react-icons/md"
import i18n from "../../config/i18n"

interface Props<T extends Record<string, any>> {
  before_row_render?: (obj: T | any, number: number) => any | T
  after_row_render?: (obj: T | any, number: number) => any | T
  gridTemplateColumns?: string
  table_rows_props?: any
  table_title: T | any
  data: TableType<T>
  table_footer?: TableFooter
}

interface TableFooter {
  start: any
  end: any
}

interface TableRowProps {
  table_row_props?: any
  entity: Record<string, any>
  table_title: Record<string, any>
}

interface TableNavigatorProps {
  current_page: number
  total_pages: number
  elements_per_page: number
  total_elements: number
  navigate: (page_number: number) => void
}

export function TableNavigator({
  current_page,
  total_pages,
  navigate,
  total_elements,
  elements_per_page
}: TableNavigatorProps) {
  const render_shown_pages = (current_page: number) => {
    let shown_pages = []

    //render first page
    if (current_page >= 3) {
      shown_pages.push(
        <Button onClick={() => navigate(1)} key={1} variant="outline icon">
          {1}
        </Button>
      )
    }

    //render previous page
    if (current_page > 1) {
      shown_pages.push(
        <Button
          onClick={() => navigate(current_page - 1)}
          key={current_page - 1}
          variant="outline icon"
        >
          {current_page - 1}
        </Button>
      )
    }

    //render current page
    shown_pages.push(
      <Button key={current_page} variant="icon">
        {current_page}
      </Button>
    )

    //render one page after
    if (current_page < total_pages) {
      shown_pages.push(
        <Button
          key={current_page + 1}
          onClick={() => navigate(current_page + 1)}
          variant="outline icon"
        >
          {current_page + 1}
        </Button>
      )
    }

    if (total_pages > current_page + 2) {
      shown_pages.push(
        <Button key={current_page + 2} variant="dim icon rm-bg">
          <MdOutlineMoreHoriz />
        </Button>
      )
      shown_pages.push(
        <Button
          key={total_pages}
          onClick={() => navigate(total_pages)}
          variant="outline icon"
        >
          {total_pages}
        </Button>
      )
    }

    return shown_pages
  }
  return total_elements > elements_per_page ? (
    <div className="table__navigator row-center md-gap">
      <Button
        variant="icon secondary"
        onClick={() => current_page !== 1 && navigate(current_page - 1)}
      >
        {i18n.dir() === "ltr" ? (
          <MdOutlineNavigateBefore />
        ) : (
          <MdOutlineNavigateNext />
        )}
      </Button>
      <span className="row center sm-gap">
        {render_shown_pages(current_page)}
      </span>
      <Button
        variant="icon secondary"
        onClick={() =>
          current_page !== total_pages && navigate(current_page + 1)
        }
      >
        {i18n.dir() === "ltr" ? (
          <MdOutlineNavigateNext />
        ) : (
          <MdOutlineNavigateBefore />
        )}
      </Button>
    </div>
  ) : (
    <></>
  )
}

function TableRow({ entity, table_title }: TableRowProps) {
  const cells = []
  let cell_key = 0
  for (const key in table_title) {
    if (entity && entity.hasOwnProperty(key)) {
      cells.push(
        <div key={cell_key} className={`cell ${key}__cell`}>
          {entity[key]}
        </div>
      )
      cell_key += 1
    } else {
      cells.push(
        <div key={cell_key} className={`cell ${key}__cell`}>
          Loading...
        </div>
      )
      cell_key += 1
    }
  }
  return <div className="row-wrapper">{cells}</div>
}

export default function Table<T extends Record<string, any>>(props: Props<T>) {
  const table_title_object = props.table_title as object
  const table_columns = Object.keys(table_title_object).length
  const gridTemplateColumns = props.gridTemplateColumns
    ? props.gridTemplateColumns
    : " 1fr ".repeat(table_columns)

  const CSS: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: gridTemplateColumns
    // gridGap: 10,
  }

  const rows = props.data.elements.map((entity, index) => {
    let mod_entity = { ...entity }
    let entity_id = mod_entity.id ? mod_entity.id : new Date().getTime()
    if (props.before_row_render) {
      mod_entity = props.before_row_render(entity, index)
    }
    return (
      <TableRow
        key={entity_id}
        entity={mod_entity}
        table_title={props.table_title}
      />
    )
  })

  return (
    <div className="table">
      <div className="table__title" style={CSS}>
        <TableRow table_title={props.table_title} entity={props.table_title} />
      </div>
      <div className="table__content" style={CSS}>
        {rows}
      </div>
      {props.table_footer ? (
        <div className="table__footer">
          <div className="row-wrapper">
            <div className="cell start__cell">{props.table_footer.start}</div>
            <div className="cell end__cell">{props.table_footer.end}</div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
