import React, { FormEvent } from "react"
import FormInput from "../../common/FormInput"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { setItem } from "../../../features/item/itemSlice"
import FormSelect from "../../common/FormSelect"
import Utils from "../../../utils/Utils"
function ItemCost() {
  const { t } = useTranslation()
  const { createItem } = useAppSelector(state => state.item)
  const { item } = useAppSelector(state => state.item.createItem)
  const { identity } = useAppSelector(state => state.identity.getIdentity)

  const dispatch = useAppDispatch()

  const onFormChange = (
    e:
      | FormEvent<HTMLInputElement>
      | React.SyntheticEvent<HTMLSelectElement, Event>
  ) =>
    dispatch(
      setItem({
        ...item,
        //@ts-ignore
        [e.target.name]: e.target.value
      })
    )

  return (
    <div className="container md-gap">
      <FormInput
        onChange={e =>
          dispatch(
            setItem({
              ...item,
              costPerQuantity: (e.target.value as unknown) as number,
              costPerUnit: Utils.round(Number(e.target.value) / item.quantity)
            })
          )
        }
        error={createItem.itemFieldError.costPerQuantity}
        value={item.costPerQuantity}
        unit={t("currency.EGP") as string}
        label={t("itemDetails.costPerQuantity")}
        name="costPerQuantity"
        placeholder={t("itemForm.costPerQuantityPlaceholder") as string}
        type="number"
      />
      <FormInput
        onChange={e =>
          dispatch(
            setItem({
              ...item,
              costPerQuantity: Utils.round(
                Number(e.target.value) * item.quantity
              ),
              costPerUnit: (e.target.value as unknown) as number
            })
          )
        }
        value={item.costPerUnit}
        unit={t("currency.EGP") as string}
        label={t("itemDetails.costPerUnit." + identity.unit)}
        name="costPerUnit"
        placeholder={
          t("itemForm.costPerUnitPlaceholder." + identity.unit) as string
        }
        type="number"
      />
    </div>
  )
}

export default ItemCost
