import React, { useContext } from "react"
import { Routes, Route } from "react-router-dom"
import { Tab } from "../layout"

import TerminalItems from "../templates/terminal/TerminalItems"
import TerminalItem from "../templates/terminal/TerminalItem"
import TerminalCart from "../templates/terminal/TerminalCart"
import TerminalHome from "../templates/TerminalHome"
import TerminalLogin from "../components/auth/TerminalLogin"
import { ProfileContext } from "../context/ProfileContext"

export function TerminalRouter() {
  const { profile } = useContext(ProfileContext)

  if (!profile) return <TerminalLogin />
  return (
    <Tab>
      <Routes>
        <Route path="home/*" element={<TerminalHome />} />
        <Route path="item/*">
          <Route path="" element={<TerminalItems />} />
          <Route path=":barcode" element={<TerminalItem />} />
        </Route>
        <Route path="cart/*" element={<TerminalCart />} />
      </Routes>
    </Tab>
  )
}
