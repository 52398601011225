import React, { useEffect } from "react"
import Template from "../../layout/Template"
import { ItemCost, ItemQuantity, ItemStatus } from "../../components/items/edit"
import { useAppDispatch, useAppSelector } from "../../hooks"
import Analyse from "../../components/common/Analyse"
import ItemPricePerUnit from "../../components/items/edit/ItemPricePerUnit"
import Header from "../../components/common/Header"
import { useTranslation } from "react-i18next"

import {
  resetErrorMessage,
  resetItem,
  setItem
} from "../../features/item/itemSlice"
import IdentityGallery from "../../components/identity/read/IdentityGallery"
import { ImageSize } from "../../components/common/Img"
import { getIdentityByBarocde } from "../../features/identity/trunk"

import Barcode from "../../components/common/Barcode"
import { useNavigate, useSearchParams } from "react-router-dom"
import Scan from "../../components/common/Scan"
import { createItem } from "../../features/item/trunk"
import { showAlert } from "../../features/appSlice"
import { AlertVariant } from "../../types/AlertType"
import { resetGetIdentity } from "../../features/identity/identitySlice"
import useBarcodeScanner from "../../hooks/useBarcodeScanner"

function CreateItem() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { item, statusCode, errorMessage } = useAppSelector(
    state => state.item.createItem
  )
  const navigate = useNavigate()
  const { getIdentity } = useAppSelector(state => state.identity)
  const {
    scannedBarcode: { barcode, autoGenerateBarcode },
    resetScannedBarcode
  } = useBarcodeScanner()
  //INITIAL USE EFFECT
  useEffect(() => {
    //INITIAL GET ITEM IDENTITY
    if (barcode) {
      dispatch(getIdentityByBarocde(barcode))
    }

    if (autoGenerateBarcode) {
      navigate(`/app/identity/create?autoGenerateBarcode=true`)
    }

    return () => {
      dispatch(resetGetIdentity())
    }
  }, [barcode])

  useEffect(() => {
    //IF IDENTITY WAS NOT FOUND AND BARCODE IS SCANNED
    if (getIdentity.statusCode == 404 && barcode) {
      resetScannedBarcode()
      navigate(`/app/identity/create?barcode=${barcode}`)
    }

    //IF IDENTITY WAS FOUND
    if (getIdentity.statusCode == 200 && barcode) {
      dispatch(
        setItem({
          ...item,
          barcode: barcode
        })
      )
    }
  }, [getIdentity.statusCode])

  useEffect(() => {
    if (statusCode == 201) {
      dispatch(
        showAlert({
          messageKey: "common.alert.message.addItemSuccess",
          variant: AlertVariant.success
        })
      )
      dispatch(resetItem())
    }

    if (errorMessage) {
      dispatch(
        showAlert({
          messageKey: errorMessage,
          variant: AlertVariant.error
        })
      )
      dispatch(resetErrorMessage())
    }
  }, [statusCode, errorMessage])

  useEffect(() => {
    return () => {
      dispatch(resetGetIdentity())
      dispatch(resetItem())
    }
  }, [])

  if (!barcode) {
    return <Scan />
  }

  if (getIdentity.statusCode == 200) {
    return (
      <Template>
        <Header
          headerCta={
            <Barcode
              title={getIdentity.identity.title}
              barcode={getIdentity.identity.barcode}
            />
          }
          subTitle={getIdentity.identity.title}
          page_title={t("common.breadcrumb.add")}
        />

        <div className="section col rg-gap center">
          <Analyse
            total_cost={item.costPerQuantity}
            total_price={item.pricePerUnit * item.quantity}
          />
          <div className="wrapper center row rg-gap ">
            <div className="wrapper main rg-gap col">
              <ItemPricePerUnit />
              <ItemQuantity />
              <ItemCost />
            </div>
            <div className="wrapper aside rg-gap col">
              <ItemStatus
                showOriginSelect
                onSave={() => dispatch(createItem(item))}
              />
              <IdentityGallery
                imageUrls={getIdentity.identity.imageUrls}
                imageSize={ImageSize.FIT}
              />
            </div>
          </div>
        </div>
      </Template>
    )
  }
  return <></>
}

export default CreateItem
