import React, { useEffect } from "react"
import Img, { ImageSize } from "./Img"
import { scan } from "../../assets/images"
import Button from "./Button"
import BarcodeScanner from "./BarcodeScanner"
import { useSearchParams } from "react-router-dom"

type Props = {
  onScan?: (barcode: string) => void
}
function Scan({ onScan }: Props) {
  const [query, setQueryParam] = useSearchParams()

  const handleScan = (barcode: string) => {
    setQueryParam(param => {
      param.append("barcode", barcode)
      return param
    })
    onScan && onScan(barcode)
  }

  return (
    <section style={{ margin: "auto" }} className="col-center md-gap">
      <BarcodeScanner onScan={e => handleScan(e.value)} />
      <Img imageSize={ImageSize.MEDUIM} src={scan} />
      <p>قم بمسح الباركود او اضغط على CTRL + ENTER للاستمرار</p>
      <p>او</p>
      <Button
        role="link"
        to="/app/identity/create?autoGenerateBarcode=true"
        variant="sm outline"
      >
        توليد تلقائي
      </Button>
    </section>
  )
}

export default Scan
