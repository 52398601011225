import React, { useState, ChangeEvent, FormEvent } from "react"
import FormInput from "../../common/FormInput"
import Button from "../../common/Button"
import FetchWrapper from "../../../utils/FetchWrapper"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { setIdentity } from "../../../features/identity/identitySlice"
import { setFileUploadState } from "../../../features/appSlice"

const IdentityImageUpload: React.FC = () => {
  const { fileUploadState } = useAppSelector(state => state.app)

  const dispatch = useAppDispatch()
  const { identity } = useAppSelector(state => state.identity.createIdentity)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFile(event.target.files[0])
      dispatch(
        setFileUploadState({
          ...fileUploadState
        })
      )
    }
  }

  console.log("isLoadin: ", fileUploadState.loading)
  const handleUpload = (event: FormEvent) => {
    event.preventDefault()
    if (selectedFile) {
      const formData = new FormData()
      formData.append("file", selectedFile as File)
      //START UPLOAD PROCCESS
      dispatch(
        setFileUploadState({
          ...fileUploadState,
          loading: true,
          progress: 0
        })
      )

      const xhr = new XMLHttpRequest()

      //UPDATE UPLOAD PROGRESS
      xhr.upload.onprogress = event => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100
          dispatch(
            setFileUploadState({
              ...fileUploadState,
              loading: true,
              progress: Math.round(progress)
            })
          )
        }
      }

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          //UPLOAD SUCCESS
          if (xhr.status === 200) {
            const data = JSON.parse(xhr.responseText)

            //UPDATE IDENTITY APP FILE ID
            dispatch(
              setIdentity({
                ...identity,
                appFileName: data.fileName
              })
            )

            //SET THE UPLOAD STATE TO COMPLETED
            dispatch(
              setFileUploadState({
                message: data.url,
                loading: false,
                progress: 100
              })
            )
            setSelectedFile(null)

            //UPLOAD FAILD
          } else {
            const data = JSON.parse(xhr.responseText)
            dispatch(
              setFileUploadState({
                ...fileUploadState,
                message: data.errorMessage,
                loading: false
              })
            )
          }
        }
      }

      xhr.open("POST", `${FetchWrapper.apiUrl}/static/upload`, true)
      xhr.send(formData)
    }
  }

  return (
    <div className="container md-gap">
      <div className="row-center jc-sb">
        <h2>رفع الصورة</h2>
        {fileUploadState.loading && (
          <progress
            className="start"
            value={fileUploadState.progress}
            max="100"
          />
        )}
      </div>
      <form
        className="flex col-center"
        onSubmit={handleUpload}
        encType="multipart/form-data"
      >
        <FormInput
          type="file"
          id="file"
          name="file"
          accept="jpg"
          onChange={handleFileChange}
        />

        <br />

        <div className="end">
          <Button variant="" type="submit" disabled={fileUploadState.loading}>
            ارفع
          </Button>
        </div>
      </form>
    </div>
  )
}

export default IdentityImageUpload
