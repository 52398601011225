import React, { ChangeEvent, FormEvent, useEffect } from "react"
import Scan from "../../components/common/Scan"
import { useAppDispatch, useAppSelector } from "../../hooks"
import FormInput from "../../components/common/FormInput"
import { useTranslation } from "react-i18next"
import Barcode from "../../components/common/Barcode"
import FormSelect from "../../components/common/FormSelect"
import {
  resetErrorMessage,
  resetIdentity,
  setIdentity
} from "../../features/identity/identitySlice"
import { Unit } from "../../features/identity/initialState"
import BarcodeScanner from "../../components/common/BarcodeScanner"
import { useNavigate } from "react-router-dom"
import Button from "../../components/common/Button"
import { createIdentity } from "../../features/identity/trunk"
import { showAlert } from "../../features/appSlice"
import { AlertVariant } from "../../types/AlertType"
import Template from "../../layout/Template"
import Header from "../../components/common/Header"
import useBarcodeScanner from "../../hooks/useBarcodeScanner"
import IdentityImageUpload from "../../components/identity/edit/IdentityImageUpload"

interface FileUploadState {
  selectedFile: File | null
  message: any
  loading: boolean
  progress: number
}

function CreateIdentity() {
  const dispatch = useAppDispatch()
  const { identity, statusCode, fieldError, errorMessage } = useAppSelector(
    state => state.identity.createIdentity
  )
  const { fileUploadState } = useAppSelector(state => state.app)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [redirect, setRedirect] = React.useState(false)

  const {
    scannedBarcode: { barcode, autoGenerateBarcode }
  } = useBarcodeScanner()

  React.useEffect(() => {
    //SET IDENTITY FROM SEARCH QUERY
    dispatch(
      setIdentity({
        ...identity,
        barcode: barcode ? barcode : "",
        autoGenerateBarcode: autoGenerateBarcode ? true : false
      })
    )

    //HANDLE SUCCESS
    if (statusCode == 201) {
      if (!redirect) {
        dispatch(
          showAlert({
            messageKey: "common.alert.message.addItemSuccess",
            variant: AlertVariant.success
          })
        )
        dispatch(resetIdentity())
      } else {
        const barcodeCopy = identity.barcode
        dispatch(resetIdentity())
        navigate(`/app/item/create?barcode=${barcodeCopy}`)
      }
    }

    //SHOW ERROR MESSAGE EVERYTIME
    if (errorMessage) {
      dispatch(
        showAlert({
          variant: AlertVariant.error,
          messageKey: errorMessage
        })
      )
      dispatch(resetErrorMessage())
    }

    //CREATE IDENTITY SUCCESS AND REDIRECT TO CREATE ITEM
  }, [barcode, autoGenerateBarcode, errorMessage, redirect, statusCode])

  useEffect(() => {
    return () => {
      dispatch(resetIdentity())
    }
  }, [])

  //UPDATE FIELDS
  const onChange = (e: any) => {
    dispatch(
      setIdentity({
        ...identity,
        [e.target.name]: e.target.value
      })
    )
  }

  const handleCreation = (redirect: boolean = false) => {
    setRedirect(redirect)
    dispatch(createIdentity(identity))
  }

  if (!autoGenerateBarcode && !barcode) {
    return <Scan />
  }

  return (
    <Template>
      <Header page_title="الأصناف" />
      <section className="section row">
        <BarcodeScanner
          onScan={barcode =>
            dispatch(
              setIdentity({
                ...identity,
                parentBarcode: barcode.value
              })
            )
          }
        />
        <div className="main">
          <div className="col rg-gap">
            <div className="container">
              <FormInput
                label={t("itemDetails.title")}
                name="title"
                placeholder={
                  (t("itemForm.titlePlaceholder") as unknown) as string
                }
                onInput={e => {
                  onChange(e)
                }}
                error={fieldError.title}
                value={identity.title}
              />
            </div>
            <div className="container">
              <FormInput
                type="textarea"
                label={"الوصف"}
                error={fieldError.description}
                name="description"
                placeholder={"اوصف يامعلم"}
                onInput={e => {
                  onChange(e)
                }}
                value={identity.description}
              />
            </div>
            <div className="container">
              <FormSelect
                label={t("itemDetails.unit")}
                name="unit"
                error={fieldError.unit}
                placeholder={(t("itemForm.unit") as unknown) as string}
                onInput={e => {
                  onChange(e)
                }}
                value={identity.unit}
              >
                <option value="PC">قطعة</option>
                <option value="PKG">كرتونة</option>
                <option value="CTR">برميل</option>
                <option value="KG">كيلو</option>
              </FormSelect>
            </div>
            {(identity.unit === Unit.PKG || identity.unit == Unit.CTR) && (
              <div className="container">
                <FormInput
                  label={t(`itemDetails.quantityPerUnit.${identity.unit}`)}
                  name="quantityPerUnit"
                  error={fieldError.quantityPerUnit}
                  placeholder={
                    (t(
                      `itemForm.quantityPerUnitPlaceholder.${identity.unit}`
                    ) as unknown) as string
                  }
                  onInput={e => {
                    onChange(e)
                  }}
                  value={identity.quantityPerUnit}
                />
              </div>
            )}
          </div>
        </div>
        <div className="aside col rg-gap">
          <div className="wrapper md-gap row">
            <Button
              onClick={() => handleCreation()}
              variant="flex "
              disabled={fileUploadState.loading}
            >
              أضف
            </Button>
            <Button
              onClick={() => handleCreation(true)}
              variant="flex  success"
              disabled={fileUploadState.loading}
            >
              التالي
            </Button>
          </div>
          {!identity.autoGenerateBarcode && (
            <div className="container md-gap">
              <span>باركود</span>
              <div className="flex col-center">
                <Barcode title={identity.title} barcode={identity.barcode} />
              </div>
            </div>
          )}
          {identity.parentBarcode && (
            <div className="container md-gap">
              <span>باركود الكرتونة</span>
              <div className="flex col-center">
                <Barcode
                  title={identity.barcode}
                  barcode={identity.parentBarcode}
                />
              </div>
            </div>
          )}
          <div className="">
            <FormInput
              label={`تحميل الصور تلقائي`}
              type="checkbox"
              defaultChecked={identity.autoDownloadImages}
              onChange={e => {
                dispatch(
                  setIdentity({
                    ...identity,
                    autoDownloadImages: e.target.checked
                  })
                )
              }}
            />
          </div>
          {!identity.autoDownloadImages && <IdentityImageUpload />}
        </div>
      </section>
    </Template>
  )
}

export default CreateIdentity
