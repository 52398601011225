import { ActionReducerMapBuilder } from "@reduxjs/toolkit"
import { OrderState } from "./initialState"
import {
  getOrderThunk,
  getOrdersReportThunk,
  getOrdersThunk,
  returnOrderThunk
} from "./thunk"

const extraReducers = (builder: ActionReducerMapBuilder<OrderState>) => {
  builder
    .addCase(getOrdersThunk.fulfilled, (state, action) => {
      state.getOrders.statusCode = action.payload.statusCode

      if (state.getOrders.statusCode == 200) {
        state.getOrders.ordersTable = action.payload.orderTable
        state.getOrders.endDate = action.payload.endDate
        state.getOrders.startDate = action.payload.startDate
        state.getOrders.user = action.payload.user
      }
    })
    .addCase(returnOrderThunk.fulfilled, (state, action) => {
      state.returnOrder.statusCode = action.payload.statusCode

      if (action.payload.statusCode == 200) {
        state.returnOrder.order = action.payload.body
        state.getOrder.order = action.payload.body
      } else {
        state.returnOrder.errorMessage = action.payload.body.errorMessage
      }
    })

    .addCase(getOrderThunk.fulfilled, (state, action) => {
      state.getOrder.statusCode = action.payload.statusCode
      if (action.payload.statusCode) {
        state.getOrder.order = action.payload.body
      }
    })
    .addCase(getOrdersReportThunk.fulfilled, (state, action) => {
      state.ordersReport = action.payload
    })
}

export default extraReducers
