import React, { CSSProperties, ReactNode, useEffect, useRef } from "react"
import {
  useAppDispatch,
  useAppSelector,
  useKeyboardShortcut
} from "../../hooks"
import { hideModel } from "../../features/appSlice"
import { CSSTransition } from "react-transition-group"

function Model({
  children,
  onExited
}: {
  children: ReactNode
  onExited?: () => void
}) {
  const { isVisible } = useAppSelector(state => state.app.model)

  const modelRef = useRef(null)
  const modelBodyRef = useRef(null)

  const handelClick = (e: any) => {
    if (modelRef.current) {
      if (e.target == modelRef.current) {
        dispatch(hideModel())
      }
    }
  }

  useEffect(() => {
    return () => {
      dispatch(hideModel())
    }
  }, [])

  const dispatch = useAppDispatch()

  const style: CSSProperties = {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
    background: "rgba(0,0,0,0.7)",
    zIndex: 1500
  }

  return (
    <CSSTransition
      in={isVisible}
      timeout={200}
      classNames={"my-node"}
      nodeRef={modelRef}
      onExited={onExited}
      unmountOnExit
    >
      <div ref={modelRef} onClick={handelClick} style={style}>
        <CSSTransition
          in={isVisible}
          timeout={200}
          classNames={"scale"}
          nodeRef={modelBodyRef}
          unmountOnExit
        >
          <div
            ref={modelBodyRef}
            className="col relative"
            style={{ zIndex: 2000, overflow: "hidden" }}
          >
            {children}
          </div>
        </CSSTransition>
      </div>
    </CSSTransition>
  )
}

export default Model
