export default class LocalStorage {
  public static get<T>(key: string): T | undefined {
    const myObject = localStorage.getItem(key)

    //fix undefined assignment
    if (myObject == undefined || myObject == "undefined" || myObject == null) {
      localStorage.removeItem(key)
      return undefined
    }

    return JSON.parse(myObject)
  }

  public static set<T>(key: string, value: T) {
    if (value == undefined || value == "undefined" || value == null) {
      localStorage.removeItem(key)
      return value
    }
    const myValue = JSON.stringify(value)
    localStorage.setItem(key, myValue)
    return value
  }
}
