import { StatusCodes } from "http-status-codes"
import TableType from "../../types/TableType"
import { Unit } from "../identity/initialState"

export type OrderItem = {
  id: number
  itemId: number
  orderId: number
  title: string
  barcode: number
  pricePerUnit: number
  costPerUnit: number
  quantityPerUnit: number
  unit: Unit
  quantity: number
  effectiveQuantity: number
  totalPrice: number
  totalCost: number
}

const getOrderInitialState = {
  statusCode: 0,
  order: {
    id: "",
    totalPrice: 0,
    totalCost: 0,
    dateCreated: "",
    orderItems: [] as Array<OrderItem>
  }
}

export type OrderType = typeof getOrderInitialState.order

const today = new Date()
const todayDate = today.toISOString().split("T")[0]
const tommorow = new Date(today.getTime() + 24 * 60 * 60 * 1000) // Adding 24 hours in milliseconds
const tommorowDate = tommorow.toISOString().split("T")[0]

const getOrdersInitialState = {
  statusCode: 0,
  pageNumber: 1,
  startDate: todayDate,
  endDate: tommorowDate,
  user: "",
  ordersTable: {
    current_page: 0,
    total_pages: 0,
    elements_per_page: 0,
    total_elements: 0,
    remaining_elements: 0,
    elements: [] as Array<OrderType>
  } as TableType<OrderType>
}

const ordersReportInitialState = {
  statusCode: 0,
  startDate: todayDate,
  endDate: tommorowDate,
  user: "",
  report: {
    revenue: 0,
    expenses: 0,
    profit: 0
  }
}

const returnOrderInitialState = {
  statusCode: 0,
  order: {} as OrderType,
  errorMessage: ""
}

const initialState = {
  getOrders: getOrdersInitialState,
  getOrder: getOrderInitialState,
  ordersReport: ordersReportInitialState,
  returnOrder: returnOrderInitialState
}

export default initialState

export type OrderState = typeof initialState
