import TableType from "../types/TableType"

class Utils {
  public static round(number: number | undefined) {
    const rounded: number =
      Math.round(((number as unknown) as number) * 100) / 100
    if (!isNaN(rounded)) return rounded
    return 0
  }

  public static getTableFromHeaders<T>(
    responseHeaders: Headers,
    elements: Array<T>
  ) {
    const table: TableType<T> = {
      total_elements: Number(responseHeaders.get("X-Total-Count")),
      total_pages: Number(responseHeaders.get("X-Total-Pages")),
      elements_per_page: Number(responseHeaders.get("X-Elements-Per-Page")),
      elements: elements,
      remaining_elements: Number(responseHeaders.get("X-Elements-Items")),
      current_page: Number(responseHeaders.get("X-Current-Page"))
    }

    return table
  }
}

export default Utils
