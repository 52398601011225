import React from "react"
import { useTranslation } from "react-i18next"
import PageHeader from "../components/common/Header"
import Template from "../layout/Template"
function TerminalHome() {
  const { t } = useTranslation()
  return (
    <Template>
      <PageHeader page_title={t("homePage.title")} header_cta={<></>} />
      <h1>أذهب الى العربة او المنتجات</h1>
    </Template>
  )
}

export default TerminalHome
