import { DefaultTFuncReturn } from "i18next"
import React, { ReactNode } from "react"

type Props = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  label?: string | DefaultTFuncReturn
  error?: string
  children: ReactNode
}

function FormSelect(props: Props) {
  return (
    <span className="col sm-gap form-item">
      {props.label && <label htmlFor={props.name}>{props.label}</label>}
      <span className="flex row-center">
        <select {...props} className={"input " + props.className}>
          {props.children}
        </select>
      </span>
      {props.error ? (
        <span className="form-input__error">{props.error}</span>
      ) : (
        <></>
      )}
    </span>
  )
}

export default FormSelect
