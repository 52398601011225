import React, { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { useParams } from "react-router-dom"
import { createIdentity, getIdentityById } from "../../features/identity/trunk"
import {
  resetGetIdentity,
  setIdentity
} from "../../features/identity/identitySlice"
import Template from "../../layout/Template"
import Header from "../../components/common/Header"
import BarcodeScanner from "../../components/common/BarcodeScanner"
import FormInput from "../../components/common/FormInput"
import FormSelect from "../../components/common/FormSelect"
import { CreateIdentity, Unit } from "../../features/identity/initialState"
import Button from "../../components/common/Button"
import Barcode from "../../components/common/Barcode"
import { useTranslation } from "react-i18next"

function UpdateIdentity() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const {
    getIdentity,
    createIdentity: { identity, fieldError }
  } = useAppSelector(state => state.identity)
  const { id } = useParams() as { id: string }

  //UPDATE FIELDS
  const onChange = (e: any) => {
    dispatch(
      setIdentity({
        ...identity,
        [e.target.name]: e.target.value
      })
    )
  }

  useEffect(() => {
    dispatch(getIdentityById(id))

    if (getIdentity.statusCode == 200) {
      dispatch(
        setIdentity({
          ...((getIdentity.identity as unknown) as CreateIdentity),
          barcode: getIdentity.identity.barcode
        })
      )
    }

    return () => {
      dispatch(resetGetIdentity())
    }
  }, [getIdentity.statusCode])

  return (
    <Template>
      <Header page_title="الأصناف" />
      <section className="section row">
        <BarcodeScanner
          onScan={barcode =>
            dispatch(
              setIdentity({
                ...identity,
                parentBarcode: barcode.value
              })
            )
          }
        />
        <div className="main">
          <div className="col rg-gap">
            <div className="container">
              <FormInput
                label={t("itemDetails.title")}
                name="title"
                placeholder={
                  (t("itemForm.titlePlaceholder") as unknown) as string
                }
                onInput={e => {
                  onChange(e)
                }}
                error={fieldError.title}
                value={identity.title}
              />
            </div>
            <div className="container">
              <FormInput
                type="textarea"
                label={"الوصف"}
                error={fieldError.description}
                name="description"
                placeholder={"اوصف يامعلم"}
                onInput={e => {
                  onChange(e)
                }}
                value={identity.description}
              />
            </div>

            {(identity.unit === Unit.PKG || identity.unit == Unit.CTR) && (
              <div className="container">
                <FormInput
                  label={t(`itemDetails.quantityPerUnit.${identity.unit}`)}
                  name="quantityPerUnit"
                  error={fieldError.quantityPerUnit}
                  placeholder={
                    (t(
                      `itemForm.quantityPerUnitPlaceholder.${identity.unit}`
                    ) as unknown) as string
                  }
                  onInput={e => {
                    onChange(e)
                  }}
                  value={identity.quantityPerUnit}
                />
              </div>
            )}
          </div>
        </div>
        <div className="aside col rg-gap">
          <div className="wrapper md-gap row">
            <Button onClick={() => createIdentity(identity)} variant="flex ">
              حفظ
            </Button>
          </div>
          <div className="container md-gap">
            <span>باركود</span>
            <div className="flex col-center">
              {!identity.autoGenerateBarcode ? (
                <Barcode title={identity.title} barcode={identity.barcode} />
              ) : (
                <span className="dim"> توليد تلقائي</span>
              )}
            </div>
          </div>
          {identity.parentBarcode && (
            <div className="container md-gap">
              <span>باركود الكرتونة</span>
              <div className="flex col-center">
                <Barcode
                  title={identity.barcode}
                  barcode={identity.parentBarcode}
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </Template>
  )
}

export default UpdateIdentity
