import { createAsyncThunk } from "@reduxjs/toolkit"
import { CreateIdentity, IdentityType, UpdateIdentity } from "./initialState"
import FetchWrapper from "../../utils/FetchWrapper"
import TableType from "../../types/TableType"

export const getIdentityById = createAsyncThunk(
  "identity/getIdentiyById",
  async (id: string) => {
    const route = `/identity/${id}`
    const res = await FetchWrapper.get(route)
    const body = await res.json()

    return {
      body,
      statusCode: res.status
    }
  }
)

type GetIdentitiesRequest = {
  pageNumber: number
  searchQuery: string
  barcode: string
}

export const getIdentities = createAsyncThunk(
  "identity/getIdentities",
  async ({ pageNumber, searchQuery, barcode }: GetIdentitiesRequest) => {
    const pageFilter = `page=${pageNumber}`
    const titleFilter = searchQuery ? `&title=${searchQuery}` : ""
    const barcodeFilter = barcode ? `&barcode=${barcode}` : ""

    const route = `/store/1/identity?${pageFilter}${titleFilter}${barcodeFilter}`
    const res = await FetchWrapper.get(route)
    const body = await res.json()

    const identities: Array<IdentityType> = body
    const responseHeaders = res.headers

    const identityTable: TableType<IdentityType> = {
      total_elements: Number(responseHeaders.get("X-Total-Count")),
      total_pages: Number(responseHeaders.get("X-Total-Pages")),
      elements_per_page: Number(responseHeaders.get("X-Elements-Per-Page")),
      elements: identities,
      remaining_elements: Number(responseHeaders.get("X-Elements-Items")),
      current_page: Number(responseHeaders.get("X-Current-Page"))
    }

    return {
      identityTable,
      searchQuery,
      barcode,
      pageNumber: 1,
      statusCode: 0
    }
  }
)

export const getIdentityByBarocde = createAsyncThunk(
  "identity/getIdentityByBarocde",
  async (barcode: string) => {
    const route = `/store/1/identity/${barcode}`
    const req = await FetchWrapper.get(route)
    const body = await req.json()

    return {
      body,
      statusCode: req.status
    }
  }
)

export const createIdentity = createAsyncThunk(
  "identity/createIdentity",
  async (identity: CreateIdentity) => {
    const route = `/store/1/identity`
    const req = await FetchWrapper.post(route, identity)
    const body = await req.json()
    return {
      body,
      statusCode: req.status
    }
  }
)

export const updateIdentity = createAsyncThunk(
  "identity/updateIdentity",
  async (identity: UpdateIdentity) => {
    const route = `/store/1/item`
    const req = await FetchWrapper.put(route, identity)
    const body = await req.json()
    return {
      body,
      statusCode: req.status
    }
  }
)

export const deleteIdentity = createAsyncThunk(
  "identity/deleteIdentity",
  async (id: string) => {
    const route = `/identity/${id}`
    const req = await FetchWrapper.delete(route)

    return {
      statusCode: req.status
    }
  }
)
