import React from "react"
import { Routes, Route } from "react-router-dom"
import Items from "../templates/item/Items"
import ItemProfile from "../templates/item/Item"
import SupplyItem from "../templates/item/Supply"
import Unpack from "../templates/item/Unpack"
import CreateItem from "../templates/item/CreateItem"
import UpdateItem from "../templates/item/UpdateItem"

export function ItemRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Items />} />
      <Route path=":id" element={<ItemProfile />} />
      <Route path="create" element={<CreateItem />} />
      <Route path=":id/update" element={<UpdateItem />} />
      <Route path=":id/supply" element={<SupplyItem />} />
      <Route path=":id/unpack" element={<Unpack />} />
    </Routes>
  )
}

// Add similar sections for Orders, Cart, Test, etc.
