import { ActionReducerMapBuilder } from "@reduxjs/toolkit"
import { IdentityState } from "./initialState"
import {
  createIdentity,
  deleteIdentity,
  getIdentities,
  getIdentityByBarocde,
  getIdentityById
} from "./trunk"

const extraReducers = (builder: ActionReducerMapBuilder<IdentityState>) => {
  builder
    .addCase(getIdentityById.fulfilled, (state, action) => {
      if (action.payload.statusCode == 200) {
        state.getIdentity.identity = action.payload.body
      }
      state.getIdentity.statusCode = action.payload.statusCode
      state.getIdentity.isLoading = false
    })
    .addCase(getIdentityByBarocde.fulfilled, (state, action) => {
      if (action.payload.statusCode == 200) {
        state.getIdentity.identity = action.payload.body
      }
      state.getIdentity.statusCode = action.payload.statusCode
      state.getIdentity.isLoading = false
    })

    //CREATE IDENTITY
    .addCase(createIdentity.fulfilled, (state, action) => {
      //ALWAYS SET STATUS CODE
      state.createIdentity.statusCode = action.payload.statusCode

      //IF REQUEST RETURNED FIELDS ERRORS
      if (action.payload.statusCode == 400) {
        state.createIdentity.fieldError = action.payload.body
      }

      //IF SUCCESS
      if (action.payload.statusCode === 201) {
        state.createIdentity.identity = action.payload.body

        //IF ERROR MESSAGE
      } else {
        state.createIdentity.errorMessage = action.payload.body.errorMessage
      }
    })

    //GET IDENTITIES
    .addCase(getIdentities.fulfilled, (state, action) => {
      state.getIdentities = action.payload
    })
    .addCase(deleteIdentity.fulfilled, (state, action) => {
      state.deleteIdentity.statusCode = action.payload.statusCode
    })
}

export default extraReducers
