import React from "react"
import "./styles/Nav.css"
import { pp } from "../../assets/images"
import LanguageSelector from "./LanguageSelector"
import LocalStorage from "../../utils/LocalStorage"
import User from "../../types/UserType"
import { Profile } from "../../context/ProfileContext"
function Nav() {
  const profile = LocalStorage.get<Profile>("profile")
  return (
    <nav>
      <div className="row-center jc-sb">
        <div className="sm-gap row row-center end">
          <img width={40} style={{ borderRadius: "50%" }} src={pp} alt="" />
          <strong>
            <p>{profile && profile.fullName}</p>
          </strong>
        </div>
      </div>
    </nav>
  )
}

export default Nav
