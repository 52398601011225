import { createAsyncThunk } from "@reduxjs/toolkit";
import FetchWrapper from "../../../utils/FetchWrapper";
import { HttpStatusCode } from "axios";
import { showAlert } from "../../appSlice";
import { AlertVariant } from "../../../types/AlertType";
import { Category } from "../initialState";
import { ServerErrorMessage } from "../../../types/ServerResponse";
import getCategories from "./getCategories";

export type CreateCategory = {
  id: number;
  name: string;
  parentId?: number;
};

const createCategory = createAsyncThunk(
  "category/createCategory",
  async ({ name, parentId }: CreateCategory, { dispatch }) => {
    const categoryRes = await FetchWrapper.post("/category", {
      name,
      parentId
    });
    const body = await categoryRes.json();

    console.log(name, parentId);
    console.log(body);

    if (categoryRes.status == HttpStatusCode.Created) {
      dispatch(
        showAlert({
          variant: AlertVariant.success,
          messageKey: "common.alert.message.createCategorySuccess"
        })
      );
      dispatch(getCategories(false));

      return body as Category;
    } else {
      dispatch(
        showAlert({
          variant: AlertVariant.error,
          messageKey: body.error_message
        })
      );
    }
  }
);

export default createCategory;
