import React from "react"
import "./styles/Input.css"
import { DefaultTFuncReturn } from "i18next"

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  unit?: any
  label?: string | DefaultTFuncReturn
  placeholder?: string | DefaultTFuncReturn
  error?: string
  isDim?: false
}

function FormInput(props: Props) {
  const spanError = props.error ? (
    <span className="form-input__error">{props.error}</span>
  ) : (
    <></>
  )

  //UNIT PROVIDED
  if (props.unit) {
    return (
      <span className={`col flex sm-gap${props.error ? "input__error" : ""}`}>
        {props.label && <label htmlFor={props.name}>{props.label}</label>}
        <span className="row-center flex">
          <input {...props} className={"input " + props.className} />
          <span className="unit">{props.unit}</span>
        </span>
        {spanError}
      </span>
    )
  }

  //CHECKBOX
  if (props.type === "checkbox") {
    return (
      <span
        className={`row-center sm-gap ${props.error ? "input__error" : ""}`}
      >
        <input {...props} className={"input " + props.className} />
        {props.label && <label htmlFor={props.name}>{props.label}</label>}
        {spanError}
      </span>
    )
  }

  //TEXTAREA
  if (props.type == "textarea") {
    return (
      <span className={`flex col sm-gap ${props.error ? "input__error" : ""}`}>
        {props.label && <label htmlFor={props.name}>{props.label}</label>}
        {/* @ts-ignore */}
        <textarea {...props} className={"input " + props.className} />
        {spanError}
      </span>
    )
  }

  //OTHER
  return (
    <span className={`flex col sm-gap ${props.error ? "input__error" : ""}`}>
      {props.label && <label htmlFor={props.name}>{props.label}</label>}
      <input {...props} className={"input " + props.className} />
      {spanError}
    </span>
  )
}

export default FormInput
