import { ActionReducerMapBuilder } from "@reduxjs/toolkit"
import { CartState } from "./initialState"
import {
  checkoutCartThunk,
  clearCartThunk,
  createCartItemThunk,
  deleteCartItemThunk,
  getCartThunk,
  updateCartItemThunk
} from "./thunk"

export const extraReducers = (builder: ActionReducerMapBuilder<CartState>) => {
  builder
    .addCase(getCartThunk.fulfilled, (state, action) => {
      state.getCart.statusCode = action.payload.statusCode
      state.getCart.cart = action.payload.body
    })
    .addCase(updateCartItemThunk.fulfilled, (state, action) => {
      state.updateCartItem.statusCode = action.payload.statusCode
      if (action.payload.statusCode == 201) {
        state.getCart.cart = action.payload.body
      } else {
        state.updateCartItem.errorMessage = action.payload.body.errorMessage
      }
    })
    .addCase(clearCartThunk.fulfilled, (state, action) => {
      if (action.payload.statusCode == 200) {
        state.getCart.cart = action.payload.body
      }
    })
    .addCase(createCartItemThunk.fulfilled, (state, action) => {
      state.createCartItem.statusCode = action.payload.statusCode
      if (state.createCartItem.statusCode == 201) {
        state.getCart.cart = action.payload.body
      } else {
        state.createCartItem.errorMessage = action.payload.body.errorMessage
      }
    })
    .addCase(deleteCartItemThunk.fulfilled, (state, action) => {
      if (action.payload.statusCode == 200) {
        state.getCart.cart = action.payload.body
      }
    })
    .addCase(checkoutCartThunk.fulfilled, (state, action) => {
      state.checkoutCart.statusCode = 201
      if (action.payload.statusCode == 201) {
        state.getCart.cart = action.payload.body
      }
    })
}
