import { createAsyncThunk } from "@reduxjs/toolkit"
import FetchWrapper from "../../utils/FetchWrapper"
import LocalStorage from "../../utils/LocalStorage"
import { Profile } from "../../context/ProfileContext"
import Utils from "../../utils/Utils"
import { OrderType } from "./initialState"

export type GetOrders = {
  pageNumber: number
  startDate: string
  endDate: string
  user: string
}

export type GetOrder = {
  id: string
}

export const getOrderThunk = createAsyncThunk(
  "order/getOrder",
  async (getOrder: GetOrder) => {
    const profile = LocalStorage.get("profile") as Profile

    const route = `/order/${getOrder.id}`
    const res = await FetchWrapper.get(route)

    const body = await res.json()

    return {
      statusCode: res.status,
      body
    }
  }
)

export type ReturnOrder = {
  id: string
}

export const returnOrderThunk = createAsyncThunk(
  "order/returnOrder",
  async (returnOrder: ReturnOrder) => {
    const route = `/order/${returnOrder.id}/return`
    const res = await FetchWrapper.post(route)

    const body = await res.json()

    return {
      statusCode: res.status,
      body
    }
  }
)

export const getOrdersThunk = createAsyncThunk(
  "order/getOrders",
  async (getOrders: GetOrders) => {
    const profile = LocalStorage.get("profile") as Profile

    const pageFilter = `?page=${getOrders.pageNumber}`
    const endDateFilter = `&endDate=${getOrders.endDate}`
    const startDateFilter = `&startDate=${getOrders.startDate}`
    const userFilter = getOrders.user ? `&user=${getOrders.user}` : ""

    const route = `/store/${profile.storeId}/order${pageFilter}${startDateFilter}${endDateFilter}${userFilter}`
    const res = await FetchWrapper.get(route)

    const body = await res.json()

    const orderTable = Utils.getTableFromHeaders<OrderType>(res.headers, body)

    return {
      statusCode: res.status,
      orderTable,
      startDate: getOrders.startDate,
      endDate: getOrders.endDate,
      user: getOrders.user
    }
  }
)

export const getOrdersReportThunk = createAsyncThunk(
  "order/getOrdersReport",
  async (getOrders: GetOrders) => {
    const profile = LocalStorage.get("profile") as Profile

    const startDateFilter = `?startDate=${getOrders.startDate}`
    const endDateFilter = `&endDate=${getOrders.endDate}`
    const userFilter = getOrders.user ? `&user=${getOrders.user}` : ""

    const route = `/store/${profile.storeId}/order/report${startDateFilter}${endDateFilter}${userFilter}`
    const res = await FetchWrapper.get(route)

    const body = await res.json()

    return {
      statusCode: res.status,
      startDate: getOrders.startDate,
      endDate: getOrders.endDate,
      user: getOrders.user,
      report: body
    }
  }
)
