import React from "react"
import Menu from "../components/common/Menu"

import LayoutType from "."
import { Outlet } from "react-router-dom"
import Nav from "../components/common/Nav"
function Tab({ children }: LayoutType) {
  return (
    <div className="tab__layout">
      <Menu />
      <div className="flex col">
        <Nav />
        <main className="main-content">{children ? children : <Outlet />}</main>
      </div>
    </div>
  )
}

export default Tab
