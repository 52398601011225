import React from "react"
import "./styles/Table.css"

interface Props<T extends Record<string, any>> {
  before_row_render?: (obj: T | any, number: number) => any | T
  after_row_render?: (obj: T | any, number: number) => any | T
  gridTemplateColumns?: string
  table_rows_props?: any
  tableTitle: T | any
  table_content: Array<T>
  table_footer?: TableFooter
}

interface TableFooter {
  start: any
  end: any
}

interface TableRowProps {
  table_row_props?: any
  entity: Record<string, any>
  tableTitle: Record<string, any>
}
export function TableRow({ entity, tableTitle }: TableRowProps) {
  const cells = []
  let cell_key = 0
  for (const key in tableTitle) {
    if (entity && entity.hasOwnProperty(key)) {
      cells.push(
        <div key={cell_key} className={`cell ${key}__cell`}>
          {entity[key]}
        </div>
      )
      cell_key += 1
    } else {
      cells.push(
        <div key={cell_key} className={`cell ${key}__cell`}>
          Loading...
        </div>
      )
      cell_key += 1
    }
  }

  return <div className="row-wrapper">{cells}</div>
}

export default function Table<T extends Record<string, any>>(props: Props<T>) {
  const table_title_object = props.tableTitle as object
  const table_columns = Object.keys(table_title_object).length
  const gridTemplateColumns = props.gridTemplateColumns
    ? props.gridTemplateColumns
    : " 1fr ".repeat(table_columns)

  const CSS: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: gridTemplateColumns
    // gridGap: 10,
  }

  const rows = props.table_content.map((entity, index) => {
    let mod_entity = { ...entity }
    let entity_id = mod_entity.id ? mod_entity.id : new Date().getTime()
    if (props.before_row_render) {
      mod_entity = props.before_row_render(entity, index)
    }
    return (
      <TableRow
        key={entity_id}
        entity={mod_entity}
        tableTitle={props.tableTitle}
      />
    )
  })

  return (
    <div className="table">
      <div className="table__title" style={CSS}>
        <TableRow tableTitle={props.tableTitle} entity={props.tableTitle} />
      </div>
      <div className="table__content" style={CSS}>
        {rows}
      </div>
      {props.table_footer ? (
        <div className="table__footer">
          <div className="row-wrapper">
            <div className="cell start--cell">{props.table_footer.start}</div>
            <div className="cell end--cell">{props.table_footer.end}</div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
