import React, { useEffect } from "react"
import Template from "../../layout/Template"
import CategoryTable from "../../components/categories/CategoryTable"
import Button from "../../components/common/Button"
import FormInput from "../../components/common/FormInput"
import { BiSearch } from "react-icons/bi"
import PageHeader from "../../components/common/Header"
import UnderDevelopment from "../../components/common/UnderDevelopment"

function Categories() {
  return (
    // <Template>
    //   <PageHeader page_title="التصنيفات" />
    //   <section className="section container col">
    //     <div className="wrapper row jc-sb">
    //       <div className="wrapper row main">
    //         <FormInput placeholder="ابحث ف التصنيفات" unit={<BiSearch />} />
    //       </div>
    //       <Button role="link" to="add" variant="end">
    //         اضف تصنيف
    //       </Button>
    //     </div>
    //     <CategoryTable />
    //   </section>
    // </Template>

    <UnderDevelopment />
  )
}

export default Categories
