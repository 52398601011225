import React from "react"
import Template from "../../layout/Template"
import PageHeader from "../../components/common/Header"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../hooks"

import ItemGallery from "../../components/identity/read/IdentityGallery"

function TerminalItem() {
  const { t } = useTranslation()
  const { item } = useAppSelector(state => state.item.getItem)
  const dispatch = useAppDispatch()
  const { barcode } = useParams() as { barcode: string }

  const srcs = [
    "https://m.media-amazon.com/images/I/71AdaOvTvIS._AC_SL1454_.jpg",
    "https://m.media-amazon.com/images/I/71yhOdiwwES._AC_SL1500_.jpg",
    "https://m.media-amazon.com/images/I/716E50pI6CS._AC_SL1500_.jpg"
  ] as Array<string>
  return (
    <Template>
      <PageHeader page_title={t("itemsPage.title")} />
      <section>
        <ItemGallery imageUrls={srcs} />
        <div></div>
      </section>
    </Template>
  )
}

export default TerminalItem
