import React, { CSSProperties } from "react"
import { useTranslation } from "react-i18next"
import { TableRow } from "../../common/Table"
import { useAppDispatch, useAppSelector } from "../../../hooks"

import { AiOutlineEdit } from "react-icons/ai"

import Price from "../../common/Price"
import { TableNavigator } from "../../common/ModTable"
import Button from "../../common/Button"
import { Link } from "react-router-dom"

import Img, { ImageSize } from "../../common/Img"
import { getItems } from "../../../features/item/trunk"

function ItemsTable() {
  const { t } = useTranslation()

  const {
    itemsTable,
    searchQuery,
    itemStatus,
    pageNumber,
    barcode
  } = useAppSelector(state => state.item.getItems)
  const dispatch = useAppDispatch()

  const tableTitle = {
    img_url: "",
    title: t("itemDetails.title"),
    pricePerUnit: t("itemDetails.pricePerUnit"),
    quantity: t("itemDetails.quantity.title"),
    costPerUnit: t("itemDetails.costPerUnit.title"),
    barcode: t("itemDetails.barcode"),
    action: ""
  }

  const CSS: CSSProperties = {
    gridTemplateColumns: "1fr 4fr 2fr 2fr 2fr 2fr .5fr"
  }

  const rows = itemsTable.elements.map(item => {
    let modItem: any = { ...item }

    modItem["title"] = <Link to={`${item.id}`}>{item.identity.title}</Link>

    modItem["pricePerUnit"] = <Price price={item.pricePerUnit} />
    modItem["costPerUnit"] = <Price price={item.costPerUnit} />
    modItem["barcode"] = (
      <Link to={`/app/identity/${item.identity.id}`}>
        {item.identity.barcode}
      </Link>
    )

    modItem["quantity"] = (
      <>
        {item.quantity} {t(`server.unit.${item.identity.unit}`)}
      </>
    )

    modItem["img_url"] = (
      <Img
        imageSize={ImageSize.FIT}
        heightFit
        src={item.identity.imageUrls[0]}
      />
    )

    modItem["action"] = (
      <Button
        variant="outline danger icon sm"
        role="link"
        to={`${item.id}/update`}
      >
        <AiOutlineEdit />
      </Button>
    )

    return <TableRow key={item.id} tableTitle={tableTitle} entity={modItem} />
  })

  return (
    <div className="table">
      <div className="table__title" style={CSS}>
        <TableRow tableTitle={tableTitle} entity={tableTitle} />
      </div>
      <div className="table__content" style={CSS}>
        {rows}
      </div>
      <div className="table__footer">
        <div className="cell start--cell">
          {`${itemsTable.elements.length} سلع من اصل ${itemsTable.total_elements}`}
        </div>
        <div className="cell end--cell">
          {
            <TableNavigator
              current_page={itemsTable.current_page}
              total_elements={itemsTable.total_elements}
              elements_per_page={itemsTable.elements_per_page}
              total_pages={itemsTable.total_pages}
              navigate={pageNumber =>
                dispatch(
                  getItems({ pageNumber, searchQuery, itemStatus, barcode })
                )
              }
            />
          }
        </div>
      </div>
    </div>
  )
}

export default ItemsTable
