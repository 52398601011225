import React, { useEffect, useState } from "react"

import FormInput from "../../common/FormInput"
import Button from "../../common/Button"
import { useTranslation } from "react-i18next"

import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { unpacKItem } from "../../../features/item/trunk"
import { showAlert } from "../../../features/appSlice"
import { AlertVariant } from "../../../types/AlertType"

function UnpackItemForm() {
  const { t } = useTranslation()
  const [quantity, setQuantity] = useState<number>(0)
  const { id } = useParams() as { id: string }
  const dispatch = useAppDispatch()
  const { statusCode, errorMessage } = useAppSelector(
    state => state.item.unpackItem
  )

  useEffect(() => {
    if (statusCode == 200) {
      dispatch(
        showAlert({
          variant: AlertVariant.success,
          messageKey: "common.alert.message.unpackItemSuccess"
        })
      )
    }

    if (statusCode != 200 && errorMessage) {
      dispatch(
        showAlert({
          variant: AlertVariant.error,
          messageKey: errorMessage
        })
      )
    }
  }, [statusCode, errorMessage])

  return (
    <div className="section center row">
      <div className="container main rg-gap col">
        <div className="row-center">
          <FormInput
            placeholder={"عايز تفتح كام واحدة"}
            label={t("itemDetails.quantity.title")}
            value={quantity}
            onChange={e => {
              const quantity = (e.target.value as unknown) as number
              setQuantity(quantity)
            }}
          />
        </div>
        <div className="wrapper end">
          <Button
            onClick={() => {
              dispatch(unpacKItem({ id: id, quantity: quantity }))
            }}
            variant="warning"
          >
            {"افتح"}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default UnpackItemForm
