import { ActionReducerMapBuilder } from "@reduxjs/toolkit"
import initialState, { ItemState } from "./initialState"
import {
  createItem,
  getItemById,
  getItems,
  getOriginItem,
  getTerminalItem,
  getTerminalItems,
  unpacKItem,
  updateItemTrunk
} from "./trunk"

const extraReducers = (builder: ActionReducerMapBuilder<ItemState>) => {
  builder
    .addCase(getOriginItem.fulfilled, (state, action) => {
      if (action.payload.statusCode == 200) {
        state.getItem.item = action.payload.body
      }
      state.getItem.statusCode = action.payload.statusCode
    })
    .addCase(getItems.fulfilled, (state, action) => {
      state.getItems = action.payload
    })

    .addCase(getTerminalItems.fulfilled, (state, action) => {
      state.getTerminalItems = action.payload
    })
    .addCase(getTerminalItem.fulfilled, (state, action) => {
      state.getTerminalItem.statusCode = action.payload.statusCode

      if (state.getTerminalItem.statusCode == 200) {
        state.getTerminalItem.item = action.payload.body
      }
    })
    .addCase(createItem.fulfilled, (state, action) => {
      state.createItem.statusCode = action.payload.statusCode

      if (state.createItem.statusCode == 201) {
        state.createItem.item = action.payload.body
      }

      if (state.createItem.statusCode == 400) {
        state.createItem.itemFieldError = action.payload.body
      } else {
        state.createItem.errorMessage = action.payload.body.errorMessage
      }
    })
    .addCase(getItemById.fulfilled, (state, action) => {
      state.getItem.statusCode = action.payload.statusCode
      if (action.payload.statusCode == 200) {
        state.getItem.item = action.payload.body
      }
    })
    .addCase(unpacKItem.fulfilled, (state, action) => {
      if (action.payload.statusCode == 200) {
        state.unpackItem.item = action.payload.body
        state.unpackItem.statusCode = action.payload.statusCode
      } else {
        state.unpackItem.errorMessage = action.payload.body.errorMessage
        state.unpackItem.statusCode = action.payload.statusCode
      }
    })
    .addCase(updateItemTrunk.fulfilled, (state, action) => {
      state.updateItem.statusCode = action.payload.statusCode

      if (action.payload.statusCode == 400) {
        state.createItem.itemFieldError = action.payload.body
      }

      if (action.payload.statusCode == 200) {
        state.createItem.item = action.payload.body
        state.createItem.itemFieldError = initialState.createItem.itemFieldError
      }
    })
}

export default extraReducers
