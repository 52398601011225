import React, { FormEvent } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { setItem } from "../../../features/item/itemSlice"
import FormSelect from "../../common/FormSelect"
import Button from "../../common/Button"

type Props = {
  onSave: () => void
  showOriginSelect?: boolean
}

function ItemStatus({ onSave, showOriginSelect }: Props) {
  const { t } = useTranslation()
  const { item } = useAppSelector(state => state.item.createItem)
  const dispatch = useAppDispatch()

  const onStatusChange = (
    e:
      | FormEvent<HTMLInputElement>
      | React.SyntheticEvent<HTMLSelectElement, Event>
  ) =>
    dispatch(
      setItem({
        ...item,
        //@ts-ignore
        [e.target.name]: e.target.value
      })
    )
  return (
    <div className="container md-gap">
      <FormSelect
        label={t("itemDetails.itemState")}
        name="itemStatus"
        onChange={e => onStatusChange(e)}
        value={item.itemStatus}
      >
        <option value="ACTIVE">{t("server.itemStatus.ACTIVE")}</option>
        <option value="DRAFT">{t("server.itemStatus.DRAFT")}</option>
      </FormSelect>

      {showOriginSelect && (
        <FormSelect
          label="الفرع"
          name="originId"
          onChange={e => onStatusChange(e)}
          value={item.itemStatus}
        >
          <option value="1">فرع الحديد والصلب</option>
        </FormSelect>
      )}
      <br />
      <div className="wrapper row flex">
        <Button onClick={() => onSave()} variant={"flex"} shortcut={"Enter"}>
          {t("itemForm.saveBtn")}
        </Button>
      </div>
    </div>
  )
}

export default ItemStatus
