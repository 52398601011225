export enum AlertVariant {
  success,
  info,
  error,
  warning
}

type AlertType = {
  isVisible?: boolean;
  variant: AlertVariant;
  messageKey: string;
  isUserClosed?: boolean;
  isShowen?: boolean;
};

export default AlertType;
