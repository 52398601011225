import React from "react"
import App from "../App"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import NotFound from "./NotFound"
import { TerminalRouter } from "./TerminalRouter"

import TerminalLogin from "../components/auth/TerminalLogin"
import AppRouter from "./AppRouter"
import { Page } from "../layout"
import AppLogin from "../components/auth/AppLogin"

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<App />}>
          <Route path="terminal/*" element={<TerminalRouter />} />
          <Route path="app/*" element={<AppRouter />} />
          <Route path="login/*" element={<Page />}>
            <Route path="terminal" element={<TerminalLogin />} />
            <Route path="app" element={<AppLogin />} />
          </Route>
        </Route>
        {/* NOT FOUND */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
