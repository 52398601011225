import React, { useContext } from "react"
import i18n from "./config/i18n"
import { I18nextProvider } from "react-i18next"

import { Outlet } from "react-router-dom"

import "./App.css"
import "./assets/styles/animation.css"
import { Provider } from "react-redux"
import { store } from "./features/store"
import "./assets/styles/root.css"
import "./assets/styles/theme.css"
import "./assets/styles/theme/dark.css"
import "./assets/styles/rtl.css"
import { AuthContext } from "./context/AuthContext"

import useWindowDimensions from "./hooks/useWindowDimensions"
import UnderDevelopment from "./components/common/UnderDevelopment"
import Alert from "./components/common/Alert"

function App() {
  const { user } = useContext(AuthContext)
  const { width } = useWindowDimensions()

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Alert />
        <div className="app">
          {width > 1500 ? <Outlet /> : <UnderDevelopment />}
        </div>
      </I18nextProvider>
    </Provider>
  )
}

export default App
