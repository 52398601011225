import React, { useEffect } from "react"
import BarcodeScanner from "../../components/common/BarcodeScanner"

import PageHeader from "../../components/common/Header"
import { useTranslation } from "react-i18next"
import {
  useAppSelector,
  useAppDispatch,
  useKeyboardShortcut
} from "../../hooks"

import Template from "../../layout/Template"

import CartItemsTable from "../../components/cart/CartItemsTable"
import CartSummary from "../../components/cart/CartSummary"
import { createCartItemThunk, getCartThunk } from "../../features/cart/thunk"
import { resetCreateCartItem } from "../../features/cart/cartSlice"
import { showAlert } from "../../features/appSlice"
import { AlertVariant } from "../../types/AlertType"

function TerminalCart() {
  const { t } = useTranslation()

  const { createCartItem } = useAppSelector(state => state.cart)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getCartThunk())
  }, [])

  useEffect(() => {
    if (createCartItem.errorMessage) {
      dispatch(
        showAlert({
          variant: AlertVariant.error,
          messageKey: createCartItem.errorMessage
        })
      )
      dispatch(resetCreateCartItem())
    }

    if (createCartItem.statusCode == 201) {
      dispatch(resetCreateCartItem())
    }
  }, [createCartItem.statusCode])
  return (
    <Template templateKey="cart__template">
      <BarcodeScanner
        onScan={e =>
          dispatch(
            createCartItemThunk({
              barcode: e.barcode,
              quantity: 1,
              quantityPerUnit: (e.weight as unknown) as number
            })
          )
        }
      />
      <PageHeader page_title={t("cartPage.title")} />
      <section className="section row center">
        <CartItemsTable />
        <CartSummary />
      </section>
    </Template>
  )
}

export default TerminalCart
