import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import en from "../locales/en.json"
import ar from "../locales/ar.json"

i18n.use(initReactI18next).init({
  lng: localStorage.getItem("language") || "ar",
  fallbackLng: "ar",
  supportedLngs: ["en", "ar"],

  resources: {
    en: { translation: en },
    ar: { translation: ar }
  }
})

export type Translation = typeof en
export const translation: Translation = en

export default i18n
