import React, { ReactNode } from "react"

import { useLocation } from "react-router-dom"

type Props = {
  page_title: string
  subTitle?: string
  header_cta?: ReactNode
  headerCta?: ReactNode
  navButton?: boolean
}

function Header(props: Props) {
  const location = useLocation().pathname
  const isTab = location.split("/").length > 2
  return (
    <header className="header row-center">
      <div className="row-center sm-gap">
        <h1 className="header__title">{props.page_title}</h1>
        {props.subTitle && (
          <>
            <strong className="lg-font">/</strong>
            <h1 className="lg-font">{props.subTitle}</h1>
          </>
        )}
      </div>
      {props.headerCta && <div>{props.headerCta}</div>}
    </header>
  )
}

export default Header
